import { Flex, MountOn, PositionAbsolute } from 'cdk'
import { SPACING_MD } from 'design-tokens'
import { Link } from 'react-router-dom'
import { Button, Card, Text } from 'ui'
import { Translation } from '~/components/Translation'
import { useFormFlowResponse } from '~/domains/formFlow/hooks/useFormFlowResponse'
import { getLastTherapyPath } from '~/domains/formFlow/utils/getLastTherapyPath'
import { useTherapyPathTypeById } from '~/domains/reservation/hooks/useTherapyPathByGivenId'
import { therapyPathTypeToFormFlowPrefilledConceptId } from '~/domains/therapies/utils'
import { getRoute } from '~/utils/getRoute'
import {
  TherapyPathTypeBackgroundStatic,
  TherapyPathTypeBackgroundStaticHeader,
} from '../TherapyPathTypeBackgroundStatic'
import { getTherapyBannerLabelById } from './utils/getTherapyBannerLabelById'

type TherapyBannerProps = { therapyPathId: string }

const TherapyBannerView = ({ therapyPathId }: TherapyBannerProps) => {
  const { loading, therapyPath } = useTherapyPathTypeById(therapyPathId)

  if (loading || therapyPath == null) {
    return null
  }

  return (
    <Card $backgroundColorName="grey-100" $overflow="hidden" $position="relative">
      <MountOn mediaQuery="gt-sm">
        <PositionAbsolute $bottom={0} $left={0} $right={0} $top={0} className="srns-serenis-dark">
          <TherapyPathTypeBackgroundStaticHeader therapyPathType={therapyPath} />
        </PositionAbsolute>
      </MountOn>
      <MountOn mediaQuery="lt-md">
        <PositionAbsolute $bottom="-50%" $left="-50%" $right={0} $top={0} className="srns-serenis-dark">
          <TherapyPathTypeBackgroundStatic therapyPathType={therapyPath} />
        </PositionAbsolute>
      </MountOn>
      <Flex $gap={SPACING_MD} $justify="space-between" $mdAlign="center" $mdDirection="row" $zIndex={1}>
        <Text colorName="lighter" kind="h1">
          <Translation id={getTherapyBannerLabelById(therapyPath)} />
        </Text>
        <Flex $align="center" $direction="row" $gap={SPACING_MD} $justify="flex-end" $wrap="wrap">
          <Link to={getRoute(`/start/p/${therapyPathTypeToFormFlowPrefilledConceptId[therapyPath]}`)}>
            <Button as="span" isGhost kind="tertiary">
              <Translation id="therapies.startTherapyPath.banner.cta.repeat" />
            </Button>
          </Link>
          <Link to={getRoute(`/booking/${therapyPathId}`)}>
            <Button as="span" kind="tertiary">
              <Translation id="therapies.startTherapyPath.banner.cta.book" />
            </Button>
          </Link>
        </Flex>
      </Flex>
    </Card>
  )
}

export const TherapyBanner = () => {
  const { formFlowResponse } = useFormFlowResponse()
  const therapyPathId = getLastTherapyPath(formFlowResponse)

  if (!therapyPathId) {
    return null
  }

  return <TherapyBannerView therapyPathId={therapyPathId} />
}
