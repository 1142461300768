import loadable from '@loadable/component'
import { Suspense } from 'react'
import { Redirect } from 'react-router-dom'
import { CenteredLoader } from '~/components/CenteredLoader'
import { ErrorBoundary } from '~/components/ErrorBoundary'
import { PageNoop } from '~/routes/PageNoop'
import { type Page } from '~/routes/types'
import { getRoute } from '~/utils/getRoute'
import { TherapySessionLiveExternalPageGuard } from './Guard'

const LazyTherapySessionLiveExternalPageView = loadable(() => import('./View'), {
  resolveComponent: (components) => components.TherapySessionLiveExternalPageView,
})

export const TherapySessionLiveExternalPage: Page = {
  exact: true,
  id: 'therapy-sessions.live.external',
  paths: ['/therapy-session/:therapySessionId/live/external'],
  options: {
    isVisibleAppBar: false,
    isVisibleBackButton: false,
    isVisibleNotificationsInbox: false,
    isVisibleTabNavigation: false,
    seoKey: 'therapy-session.live.external',
    title: null,
  },
  PageGuard: ({ children }) => (
    <ErrorBoundary fallback={<Redirect to={getRoute('/')} />}>
      <Suspense fallback={<CenteredLoader />}>
        <TherapySessionLiveExternalPageGuard>{children}</TherapySessionLiveExternalPageGuard>
      </Suspense>
    </ErrorBoundary>
  ),
  PageLoaded: PageNoop,
  PageProvider: PageNoop,
  PageView: () => <LazyTherapySessionLiveExternalPageView />,
}
