import { Flex, PositionRelative } from 'cdk'
import {
  BORDER_RADIUS_XS,
  BORDER_WIDTH_1,
  COLOR_LIGHTER,
  COLOR_PRIMARY_50,
  COLOR_TRANSPARENT,
  SPACING_MD,
  SPACING_XS,
} from 'design-tokens'
import styled, { css } from 'styled-components'
import { Avatar } from 'ui'
import { OverflowAutoWithShadow } from '~/components/OverflowAutoWithShadow'
import { TimeSlot } from './TimeSlot'

export const TIME_SLOT_AVATAR_HEADER_HEIGHT = 64

const Container = styled(PositionRelative)<{ $isMultiple: boolean }>`
  ${({ $isMultiple = false }) =>
    $isMultiple &&
    css`
      outline: ${BORDER_WIDTH_1} solid ${COLOR_TRANSPARENT};

      &::after {
        content: '';
        position: absolute;
        inset: 0;
        bottom: ${BORDER_WIDTH_1};
        z-index: 1;
        border-radius: ${BORDER_RADIUS_XS};
        outline: ${BORDER_WIDTH_1} solid ${COLOR_TRANSPARENT};
        pointer-events: none;
      }

      &:has(:checked) {
        outline-color: ${COLOR_LIGHTER};

        &::after {
          outline-color: ${COLOR_PRIMARY_50};
        }
      }
    `};
`

type Props = {
  day: string
  isMultiple?: boolean
  slots: Date[]
  therapistId: string
  therapistImage: string | undefined
  therapistName: string
}

export const TimeSlotDayTherapist = ({
  day,
  isMultiple = false,
  slots,
  therapistId,
  therapistImage,
  therapistName,
}: Props) => (
  <Container $backgroundColorName="neutral-40" $gap={BORDER_WIDTH_1} $grow={1} $isMultiple={isMultiple} $shrink={1}>
    {isMultiple && (
      <Flex
        $align="center"
        $backgroundColorName="lighter"
        $justify="center"
        $maxHeight={TIME_SLOT_AVATAR_HEADER_HEIGHT}
        $minHeight={TIME_SLOT_AVATAR_HEADER_HEIGHT}
      >
        <Avatar image={therapistImage} name={therapistName} size="md" />
      </Flex>
    )}
    <OverflowAutoWithShadow
      $backgroundColorName="lighter"
      $direction="column"
      $gap={SPACING_MD}
      $grow={1}
      $p={SPACING_XS}
      $pb={SPACING_MD}
      $pt={SPACING_MD}
      $shrink={1}
      offsetBefore={isMultiple ? TIME_SLOT_AVATAR_HEADER_HEIGHT : 0}
    >
      {slots.map((slot) => (
        <TimeSlot key={`${day}-${therapistId}-${slot.toISOString()}`} slot={slot} therapistId={therapistId} />
      ))}
    </OverflowAutoWithShadow>
  </Container>
)
