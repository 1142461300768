import { useRouter } from 'next/router'
import { type ReactNode, useEffect } from 'react'
import { CenteredLoader } from '~/components/CenteredLoader'
import { useAuthState } from '~/domains/auth/components/AuthStateProvider'
import { getRoute } from '~/utils/getRoute'
import { getPath } from '~/utils/nextjsRouting'

type Props = {
  children: ReactNode
  to?: string
}

export const AssertAuthenticatedRedirect = ({ children, to }: Props) => {
  const { isAuthenticated, isAuthenticating } = useAuthState()
  const router = useRouter()

  useEffect(() => {
    if (isAuthenticating) {
      return
    }

    if (isAuthenticated) {
      return
    }

    const { asPath } = router
    // @TODO(comes): investigate how to avoid creating chats if no therapy exists
    const defaultReferrer = !/\/chat\/\w+/.test(asPath) ? asPath : getRoute('/chat')
    const redirectTo = to ?? defaultReferrer

    router.replace({
      pathname: getPath('/login'),
      query: { redirectTo },
    })
  }, [isAuthenticated, isAuthenticating, router, to])

  if (isAuthenticating || !isAuthenticated) {
    return <CenteredLoader />
  }

  return <>{children}</>
}
