import { COLOR_PRIMARY_40, COLOR_PRIMARY_50 } from 'design-tokens'
import ContentLoader from 'react-content-loader'
import { Card } from 'ui'

export const ServiceInfoSkeleton = () => (
  <Card>
    <ContentLoader
      backgroundColor={COLOR_PRIMARY_40}
      foregroundColor={COLOR_PRIMARY_50}
      speed={1.5}
      viewBox="0 0 400 10"
    >
      <rect height="6" rx="3" ry="3" width="380" x="0" y="2" />
    </ContentLoader>
  </Card>
)
