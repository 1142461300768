import { Flex, Pressable } from 'cdk'
import { useRouter } from 'next/router'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Text } from 'ui'
import { Popover, PopoverContent, PopoverTrigger } from '~/components/FloatingUI/Popover'
import { Translation } from '~/components/Translation'
import { useAuthMethods } from '~/domains/auth/hooks/useAuthMethods'
import { useCurrentUser } from '~/hooks/useCurrentUser'
import { getRoute } from '~/utils/getRoute'
import { getPath } from '~/utils/nextjsRouting'

export const AppBarLogoutButton = () => {
  const history = useHistory()
  const router = useRouter()
  const { firstName } = useCurrentUser()
  const { signOut } = useAuthMethods()

  const goToHome = useCallback(() => {
    history.push(getRoute('/'))
  }, [history])

  const signOutAndGoToLogin = useCallback(async () => {
    await signOut()
    router.push(getPath('/login'))
  }, [router, signOut])

  return (
    <Popover placement="bottom-end">
      <PopoverTrigger>
        <Flex $align="center" $direction="row">
          <Flex $pl={2}>
            <Text colorName="primary" ellipsis fontWeight="600" kind="paragraph">
              {firstName}
            </Text>
          </Flex>
        </Flex>
      </PopoverTrigger>
      <PopoverContent>
        <Flex $gap={8}>
          <Pressable onClick={goToHome}>
            <Text colorName="primary" fontWeight="400" kind="caption" textAlign="left">
              <Translation id="actions.goToRoot" />
            </Text>
          </Pressable>
          <Pressable onClick={signOutAndGoToLogin}>
            <Text colorName="primary" fontWeight="400" kind="caption" textAlign="left">
              <Translation id="actions.logout" />
            </Text>
          </Pressable>
        </Flex>
      </PopoverContent>
    </Popover>
  )
}
