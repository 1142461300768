import { Flex, OverflowAuto } from 'cdk'
import { memo } from 'react'
import { useIsNativeApp } from '~/domains/appNative/hooks/useIsNativeApp'
import { MenuFooter } from './common/MenuFooter'
import { MenuGroup } from './common/MenuGroup'
import { MenuGroupTitle } from './common/MenuGroupTitle'
import { MenuGroupVoices } from './common/MenuGroupVoices'
import { MenuHeader } from './items/MenuHeader'
import { MenuItemAccount } from './items/MenuItemAccount'
import { MenuItemCommunications } from './items/MenuItemCommunications'
import { MenuItemFAQ } from './items/MenuItemFAQ'
import { MenuItemHistoricalTherapySessions } from './items/MenuItemHistoricalTherapySessions'
import { MenuItemNotifications } from './items/MenuItemNotifications'
import { MenuItemProfile } from './items/MenuItemProfile'
import { MenuItemWriteUs } from './items/MenuItemWriteUs'

export const TherapistTabMenu = memo(({ handleAutoClose }: { handleAutoClose?: () => void }) => {
  const isNativeApp = useIsNativeApp()

  return (
    <>
      <MenuHeader />

      <OverflowAuto $p={16}>
        <Flex $gap={32} $grow={1}>
          <MenuGroup>
            <MenuGroupTitle id="menu.menu" />

            <MenuGroupVoices>
              <Flex onClick={handleAutoClose}>
                <MenuItemAccount />
              </Flex>

              <Flex onClick={handleAutoClose}>
                <MenuItemHistoricalTherapySessions />
              </Flex>

              <Flex onClick={handleAutoClose}>
                <MenuItemCommunications />
              </Flex>

              {isNativeApp && (
                <Flex>
                  <MenuItemNotifications />
                </Flex>
              )}

              <Flex onClick={handleAutoClose}>
                <MenuItemProfile />
              </Flex>
            </MenuGroupVoices>
          </MenuGroup>

          <MenuGroup>
            <MenuGroupTitle id="menu.help" />

            <MenuGroupVoices>
              <MenuItemFAQ />
              <MenuItemWriteUs />
            </MenuGroupVoices>
          </MenuGroup>
        </Flex>

        <MenuFooter />
      </OverflowAuto>
    </>
  )
})
