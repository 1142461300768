import { Flex } from 'cdk'
import { useLocation } from 'react-router-dom'
import { FlowNavigationBar } from '~/components/Flow/FlowNavigationBar'
import { useCurrentUser } from '~/hooks/useCurrentUser'
import { getReservationRoutes } from '../utils/getReservationRoutes'
import { BookButton } from './BookButton'
import { GoNextButton } from './GoNextButton'
import { GoPreviousButton } from './GoPreviousButton'
import { GoRootButton } from './GoRootButton'
import { SkipBookingButton } from './SkipBookingButton'

export const ReservationFooter = () => {
  const location = useLocation()
  const { emailVerified } = useCurrentUser()

  if (!emailVerified) {
    return null
  }

  if (location.pathname.includes(getReservationRoutes('/send-verification-code'))) {
    return (
      <FlowNavigationBar>
        <Flex $direction="row" $justify="space-between">
          <GoPreviousButton />
          <GoNextButton />
        </Flex>
      </FlowNavigationBar>
    )
  }

  if (location.pathname.includes(getReservationRoutes('/check-verification-code'))) {
    return (
      <FlowNavigationBar>
        <Flex $direction="row" $justify="space-between">
          <GoPreviousButton />
          <GoNextButton />
        </Flex>
      </FlowNavigationBar>
    )
  }

  if (location.pathname.includes(getReservationRoutes('/submit'))) {
    return (
      <FlowNavigationBar>
        <Flex $direction="row" $justify="space-between">
          <GoRootButton />
          <BookButton />
        </Flex>
      </FlowNavigationBar>
    )
  }

  if (location.pathname.includes(getReservationRoutes('/skip-booking'))) {
    return (
      <FlowNavigationBar>
        <Flex $direction="row" $justify="space-between">
          <GoPreviousButton />
          <SkipBookingButton />
        </Flex>
      </FlowNavigationBar>
    )
  }

  return null
}
