import { Form } from 'cdk'
import { type PropsWithChildren } from 'react'
import { useReactHookFormContext } from '~/domains/react-hook-form'
import { type ReservationFormValues } from '~/domains/reservation/types'

type ReservationFormProps = PropsWithChildren<{
  onSubmit: ({ selectedTimeSlotWithTherapist }: ReservationFormValues) => void
}>

export const ReservationForm = ({ children, onSubmit }: ReservationFormProps) => {
  const form = useReactHookFormContext<ReservationFormValues>()

  return (
    <Form
      $backgroundColorName="lighter"
      $grow={1}
      $shrink={1}
      id="reservationFlow"
      onSubmit={form.handleSubmit(onSubmit)}
    >
      {children}
    </Form>
  )
}
