import { COLOR_LIGHTER, OPACITY_40, OPACITY_72, OPACITY_96 } from 'design-tokens'
import styled, { css } from 'styled-components'
import { type TherapyTherapyPathType } from '~/types/graphql'

const therapyPathTypeColors: Record<TherapyTherapyPathType, string | null> = {
  COUPLES_PSYCHOTHERAPY: '249 223 197',
  MYSELF_PSYCHOTHERAPY: '250 206 232',
  PATH_COACHING: '166 244 192',
  PATH_SEXOLOGY: '249 90 109',
  PATH_NUTRITION_DCA: '110 238 193',
  PATH_NUTRITION_WEIGHT_LOSS: '19 177 162',
  MYSELF_PSYCHIATRY: '157 181 249',
  PATH_ASSERTIVE_COMMUNICATION: null,
  PATH_SLEEP: null,
  UNDERAGE_PSYCHOTHERAPY: null,
}

const BubblesAnimation = styled.div<{ therapyPathType: TherapyTherapyPathType | null }>`
  ${({ therapyPathType }) =>
    !!therapyPathType && !!therapyPathTypeColors[therapyPathType]
      ? css`
          --color1: 156 138 244;
          --color2: ${therapyPathTypeColors[therapyPathType]};
          --color3: 156 138 244;
          --color4: ${therapyPathTypeColors[therapyPathType]};
          --color5: 156 138 244;
        `
      : css`
          --color1: 240 240 241;
          --color2: 176 178 187;
          --color3: 240 240 241;
          --color4: 176 178 187;
          --color5: 240 240 241;
        `}
  --color-bg1: ${COLOR_LIGHTER};
  --color-bg2: ${COLOR_LIGHTER};
  --circle-size: 80%;
  --blending: hard-light;
  --blur: 60px;

  @keyframes move-in-circle {
    0% {
      transform: rotate(0deg);
    }

    50% {
      transform: rotate(180deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes move-vertical {
    0% {
      transform: translateY(-50%);
    }

    50% {
      transform: translateY(50%);
    }

    100% {
      transform: translateY(-50%);
    }
  }

  @keyframes move-horizontal {
    0% {
      transform: translateX(-50%) translateY(-10%);
    }

    50% {
      transform: translateX(50%) translateY(10%);
    }

    100% {
      transform: translateX(-50%) translateY(-10%);
    }
  }

  position: relative;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background: linear-gradient(40deg, var(--color-bg1), var(--color-bg2));
  opacity: ${OPACITY_40};

  svg {
    display: none;
  }

  .bubbles-container {
    width: 100%;
    height: 100%;
    filter: url('#goo') blur(var(--blur));
  }
`

const Bubble = styled.div`
  position: absolute;
  mix-blend-mode: var(--blending);

  &.g1 {
    top: calc(130% - var(--circle-size) / 2);
    left: calc(50% - var(--circle-size) / 2);
    width: var(--circle-size);
    height: var(--circle-size);
    background: radial-gradient(circle at center, rgb(var(--color1) / 80%) 0, rgb(var(--color1) / 0%) 50%) no-repeat;
    opacity: ${OPACITY_96};
    transform-origin: center center;
    animation: move-vertical 70s ease infinite;
  }

  &.g2 {
    top: calc(100% - var(--circle-size) / 2);
    left: calc(50% - var(--circle-size) / 2);
    width: var(--circle-size);
    height: var(--circle-size);
    background: radial-gradient(circle at center, rgba(var(--color2) / 80%) 0, rgba(var(--color2) / 0%) 50%) no-repeat;
    opacity: ${OPACITY_96};
    transform-origin: calc(50% - 400px);
    animation: move-in-circle 40s reverse infinite;
  }

  &.g3 {
    top: calc(100% - var(--circle-size) / 2 + 200px);
    left: calc(50% - var(--circle-size) / 2 - 500px);
    width: var(--circle-size);
    height: var(--circle-size);
    background: radial-gradient(circle at center, rgba(var(--color3) / 80%) 0, rgba(var(--color3) / 0%) 50%) no-repeat;
    opacity: ${OPACITY_96};
    transform-origin: calc(50% + 400px);
    animation: move-in-circle 40s linear infinite;
  }

  &.g4 {
    top: calc(90% - var(--circle-size) / 2);
    left: calc(50% - var(--circle-size) / 2);
    width: var(--circle-size);
    height: var(--circle-size);
    background: radial-gradient(circle at center, rgba(var(--color4) / 80%) 0, rgba(var(--color4) / 0%) 50%) no-repeat;
    opacity: ${OPACITY_72};
    transform-origin: calc(50% - 200px);
    animation: move-horizontal 40s ease infinite;
  }

  &.g5 {
    top: calc(100% - var(--circle-size));
    left: calc(50% - var(--circle-size));
    width: calc(var(--circle-size) * 2);
    height: calc(var(--circle-size) * 2);
    background: radial-gradient(circle at center, rgba(var(--color5) / 80%) 0, rgba(var(--color5) / 0%) 50%) no-repeat;
    opacity: ${OPACITY_96};
    transform-origin: calc(50% - 800px) calc(50% + 200px);
    animation: move-horizontal 40s ease infinite;
  }
`

type BubblesBackgroundProps = {
  therapyPathType: TherapyTherapyPathType | null
}

export const BubblesBackground = ({ therapyPathType }: BubblesBackgroundProps) => (
  <BubblesAnimation therapyPathType={therapyPathType}>
    <svg xmlns="http://www.w3.org/2000/svg">
      <defs>
        <filter id="goo">
          <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="10" />
          <feColorMatrix in="blur" mode="matrix" result="goo" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -8" />
          <feBlend in="SourceGraphic" in2="goo" />
        </filter>
      </defs>
    </svg>
    <div className="bubbles-container">
      <Bubble className="g1" />
      <Bubble className="g2" />
      <Bubble className="g3" />
      <Bubble className="g4" />
      <Bubble className="g5" />
    </div>
  </BubblesAnimation>
)
