import {
  THERAPY_SESSION_COST_ONLINE_COACHING,
  THERAPY_SESSION_COST_ONLINE_COACHING_FIRST,
  THERAPY_SESSION_COST_ONLINE_COUPLE,
  THERAPY_SESSION_COST_ONLINE_COUPLE_FIRST,
  THERAPY_SESSION_COST_ONLINE_DCA,
  THERAPY_SESSION_COST_ONLINE_DCA_FIRST,
  THERAPY_SESSION_COST_ONLINE_MYSELF,
  THERAPY_SESSION_COST_ONLINE_MYSELF_FIRST,
  THERAPY_SESSION_COST_ONLINE_PSYCHIATRY,
  THERAPY_SESSION_COST_ONLINE_PSYCHIATRY_FIRST,
  THERAPY_SESSION_COST_ONLINE_SEXOLOGY,
  THERAPY_SESSION_COST_ONLINE_SEXOLOGY_FIRST,
  THERAPY_SESSION_COST_ONLINE_WEIGHT_LOSS,
  THERAPY_SESSION_COST_ONLINE_WEIGHT_LOSS_FIRST,
} from '~/constants'
import { type Intent } from '~/domains/auth/hooks/useSignupIntent'

type Costs = {
  therapySessionCost: number
  firstTherapySessionCost: number
}

export const getTherapySessionCostsByIntent = (intent: Intent): Costs => {
  switch (intent) {
    case 'call':
    case 'home':
    case 'journaling':
    case 'therapyMyself':
      return {
        firstTherapySessionCost: THERAPY_SESSION_COST_ONLINE_MYSELF_FIRST,
        therapySessionCost: THERAPY_SESSION_COST_ONLINE_MYSELF,
      }
    case 'coaching':
      return {
        firstTherapySessionCost: THERAPY_SESSION_COST_ONLINE_COACHING_FIRST,
        therapySessionCost: THERAPY_SESSION_COST_ONLINE_COACHING,
      }
    case 'dca':
      return {
        firstTherapySessionCost: THERAPY_SESSION_COST_ONLINE_DCA_FIRST,
        therapySessionCost: THERAPY_SESSION_COST_ONLINE_DCA,
      }
    case 'nutritionist':
      return {
        firstTherapySessionCost: THERAPY_SESSION_COST_ONLINE_WEIGHT_LOSS_FIRST,
        therapySessionCost: THERAPY_SESSION_COST_ONLINE_WEIGHT_LOSS,
      }
    case 'psychiatry':
      return {
        firstTherapySessionCost: THERAPY_SESSION_COST_ONLINE_PSYCHIATRY_FIRST,
        therapySessionCost: THERAPY_SESSION_COST_ONLINE_PSYCHIATRY,
      }
    case 'sexology':
      return {
        firstTherapySessionCost: THERAPY_SESSION_COST_ONLINE_SEXOLOGY_FIRST,
        therapySessionCost: THERAPY_SESSION_COST_ONLINE_SEXOLOGY,
      }
    case 'therapyCouple':
      return {
        firstTherapySessionCost: THERAPY_SESSION_COST_ONLINE_COUPLE_FIRST,
        therapySessionCost: THERAPY_SESSION_COST_ONLINE_COUPLE,
      }
  }
}
