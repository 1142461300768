import { useFeatureFlagsByUserId } from '~/domains/featureFlags'
import { type NavigationLink } from '../types'

const HOME: NavigationLink = {
  id: 'home',
  dataTestId: 'tab-item-home',
  route: '/',
  translation: 'menu.home',
  iconName: 'srns-home',
  iconNameActive: 'srns-home-solid',
  pathMatches: [
    '/',
    '/therapy-session/:therapyId/schedule',
    '/therapy-session/:therapySessionId/edit',
    '/therapy-session/booking/:therapy',
  ],
  exact: true,
}

const THERAPIES: NavigationLink = {
  id: 'therapies',
  dataTestId: 'tab-item-therapies',
  route: '/therapies',
  translation: 'menu.therapies',
  iconName: 'srns-therapy',
  iconNameActive: 'srns-therapy-solid',
  pathMatches: ['/therapies'],
  exact: false,
}

const PATHS: NavigationLink = {
  id: 'therapies',
  dataTestId: 'tab-item-therapies',
  route: '/paths',
  translation: 'menu.therapies',
  iconName: 'srns-therapy',
  iconNameActive: 'srns-therapy-solid',
  pathMatches: ['/paths'],
  exact: false,
}

export const usePatientNavigationLinks = (): NavigationLink[] => {
  const { isVariant } = useFeatureFlagsByUserId()

  return [
    ...(isVariant('ff_new_therapies_screen') ? [PATHS] : [HOME, THERAPIES]),
    {
      id: 'chat',
      dataTestId: 'tab-item-chat',
      route: '/chat',
      translation: 'menu.chat',
      iconName: 'srns-chat',
      iconNameActive: 'srns-chat-solid',
      pathMatches: ['/chat'],
      exact: false,
    },
    {
      id: 'diaries',
      dataTestId: 'tab-item-diaries',
      route: '/diaries',
      translation: 'menu.diaries',
      iconName: 'srns-journaling',
      iconNameActive: 'srns-journaling-solid',
      pathMatches: ['/diaries', '/journaling', '/food-journal'],
      exact: false,
    },
  ]
}
