export type PadSlotsByDay = [day: string, slotsByTherapist: [therapistId: string, slot: (Date | 'PLACEHOLDER')[]][]][]

export const padSlotsByDay = (slotsByDayAndTherapist: PadSlotsByDay): PadSlotsByDay =>
  slotsByDayAndTherapist.map(([day, slotsByTherapist]) => [
    day,
    slotsByTherapist.map(([day, slots]) => [
      day,
      slots.length % 3 === 0 ? slots : [...slots, ...Array(3 - (slots.length % 3)).fill('PLACEHOLDER')],
    ]),
  ])
