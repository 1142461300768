import { useFormTranslations } from '~/hooks/useFormTranslations'
import { useFormValidation } from '~/hooks/useFormValidation'
import { SignupFormTextField } from './SignupFormTextField'

export const SignupFormEmailField = () => {
  const { isEmail } = useFormValidation()

  const {
    errors: { required },
    helperTexts,
    labels,
    placeholders,
  } = useFormTranslations()

  return (
    <SignupFormTextField
      autoComplete="email"
      hint={helperTexts.emailSignup}
      inputMode="email"
      label={labels.email}
      name="email"
      placeholder={placeholders.email}
      rules={{
        required,
        validate: isEmail,
      }}
    />
  )
}
