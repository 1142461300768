import { Flex, OverflowAuto, Pressable } from 'cdk'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Modal, ModalProvider, ModalTitle, ModalTrigger, Text } from 'ui'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { useTherapySession } from '~/hooks/useTherapySession'
import { getRoute } from '~/utils/getRoute'

export const ManageButtonVariant1 = () => {
  const history = useHistory()
  const { id, therapist } = useTherapySession()

  const handleDeleteAppointment = useCallback(() => {
    history.push(getRoute(`/therapy-session/${id}/delete`))
  }, [history, id])

  const handleEditAppointment = useCallback(() => {
    history.push(getRoute(`/therapy-session/${id}/edit`))
  }, [history, id])

  return (
    <ModalProvider>
      <ModalTrigger>
        <Button as="span" kind="tertiary">
          <Translation id="manageTherapySession.variant1.manage" />
        </Button>
      </ModalTrigger>
      <Modal>
        <OverflowAuto>
          <Flex $gap={24}>
            <ModalTitle>
              <Translation id="manageTherapySession.variant1.title" />
            </ModalTitle>
            <TranslationMarkdown
              id="manageTherapySession.variant1.body"
              values={{ therapistFirstName: therapist?.firstName ?? '' }}
            />
            <Button kind="primary" onClick={handleEditAppointment}>
              <Translation id="manageTherapySession.variant1.openCalendar" />
            </Button>
            <Flex $borderColorName="neutral-40" $borderSizeBottom={2} $pt={24} />
            <Pressable onClick={handleDeleteAppointment}>
              <Flex $align="center" $direction="row" $gap={4} $justify="center">
                <Text>
                  <Translation id="manageTherapySession.variant1.cannotReschedule" />
                </Text>
                <Text colorName="primary">
                  <Translation id="manageTherapySession.variant1.cannotReschedule.delete" />
                </Text>
              </Flex>
            </Pressable>
          </Flex>
        </OverflowAuto>
      </Modal>
    </ModalProvider>
  )
}
