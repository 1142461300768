import { Icon } from 'icons'
import { Button } from 'ui'
import { Translation } from '~/components/Translation'

type Props = {
  onClick: () => void
}

export const DeleteRecurrencyButton = ({ onClick }: Props) => (
  <Button isGhost kind="secondary" onClick={onClick} size="sm">
    <Icon name="timer-off" size={20} /> <Translation id="actions.deleteRecurrency" />
  </Button>
)
