import loadable from '@loadable/component'
import { Flex, PageLayout } from 'cdk'
import { SPACING_LG, SPACING_MD, SPACING_XL, SPACING_XS } from 'design-tokens'
import { useBreakpoints } from 'hooks'
import { useCallback, useEffect, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom-v5-compat'
import { Text } from 'ui'
import { JournalingTutorialModal, JournalingTutorialSummary } from '~/components/JournalingTutorial'
import { MobileHookBanner } from '~/components/MobileHookBanner'
import { SentencesTutorialIntroductionModal } from '~/components/SentencesTutorial/Modal'
import { ServiceInfo } from '~/components/ServiceInfo'
import { TherapyBanner } from '~/components/TherapyBanner'
import { TherapySessionCardPatient } from '~/components/TherapySessionCardPatient'
import { Translation } from '~/components/Translation'
import { PushNotificationRequest } from '~/domains/appNative/components/PushNotificationRequest'
import { usePatientTherapies } from '~/domains/patient/hooks/usePatientTherapies'
import { useActivateReferralEffect } from '~/domains/referral/hooks/useActivateReferralEffect'
import { AskForReview } from '~/domains/reviews'
import { useCurrentUser } from '~/hooks/useCurrentUser'
import { usePatientAgenda } from '~/hooks/usePatientAgenda'
import { useReferredUserIds } from '~/hooks/useReferredUserIds'
import { useLanguage } from '~/i18n/hooks/useLanguage'
import { JournalingCard } from '~/routes/journaling/components/JournalingCard'
import { WritingIsGoodCardSmall } from '~/routes/journaling/components/WritingIsGoodCardSmall'
import { isMemberGetMemberSupported, isReferralSupported } from '~/utils/therapyPaths'
import { useIsNativeApp } from '../appNative/hooks/useIsNativeApp'
import { useModals } from '../modals'
import { LinkMemberGetMember } from '../navigation/NewNavigation'
import { CreatePartnerSuggestionBanner } from '../onboardingCouples/components/CreatePartnerSuggestionBanner'
import { CreateLegalGuardiansSuggestionBanner } from '../onboardingGuardian/components/CreateLegalGuardiansSuggestionBanner'
import { useResetSuggestedTherapistCount } from '../reservation/hooks/useResetSuggestedTherapistCount'
import { useSentenceOfTheDay } from '../sentenceOfTheDay/useSentenceOfTheDay'
import { SettingsInviteCard } from '../settings/pages/invite/components/SettingsInviteCard'
import { AgendaPollingEffect } from './AgendaPollingEffect'
import { AgendaPatientEmptyState } from './components/AgendaPatientEmptyState'
import { LiveTherapySessionSuggestion } from './components/LiveTherapySessionSuggestion'
import { MemberGetMemberSuggestion } from './components/MemberGetMemberSuggestion'
import { NextTherapySessionsModuleTitle } from './components/NextTherapySessionsModuleTitle'
import { PaymentMethodWarningSuggestion } from './components/PaymentMethodWarningSuggestion'
import { StartTherapyPath } from './components/StartTherapyPath'
import { StickerModule } from './components/StickerModule'
import { WelcomeHeader } from './components/WelcomeHeader'

const LazySentenceOfTheDay = loadable(() => import('../sentenceOfTheDay'), {
  resolveComponent: (components) => components.SentenceOfTheDay,
})

const leftMdBasis: string = '50%'
const rightMdBasis: string = '45%'

export const PatientView = () => {
  const { isSmallOnly } = useBreakpoints()
  const { firstName, referralCode } = useCurrentUser()
  const { agenda, error, loading, startPolling, stopPolling } = usePatientAgenda()
  const { therapies } = usePatientTherapies()
  const { remainingMemberGetMemberRewards } = useReferredUserIds()
  const { language } = useLanguage()
  const isNativeApp = useIsNativeApp()
  const [searchParams, setSearchParams] = useSearchParams()

  useResetSuggestedTherapistCount()

  useActivateReferralEffect()

  const showReferralCode =
    !!referralCode && (!therapies.length || therapies.some((therapy) => isReferralSupported(therapy.therapyPath.type)))

  const showMemberGetMember =
    !!remainingMemberGetMemberRewards &&
    (!therapies.length || therapies.some((therapy) => isMemberGetMemberSupported(therapy.therapyPath.type)))

  const isUnderageTherapy = therapies.find((therapy) => therapy.isUnderage)

  const { text: sentenceOfTheDay, loading: sentenceOfTheDayLoading } = useSentenceOfTheDay()

  const nextTherapySession = agenda?.nextTherapySessions?.length ? agenda?.nextTherapySessions[0] : null

  const showPaymentMethodWarning = useMemo(() => {
    if (!nextTherapySession) {
      return false
    }

    return [
      'NO_PAYMENT_METHOD',
      'PAYMENT_FAILED_FIRST_ATTEMPT',
      'PAYMENT_FAILED_FIRST_ATTEMPT_NO_PAYMENT_METHOD',
    ].includes(nextTherapySession.status)
  }, [nextTherapySession])

  const showLiveTherapySessionWarning = useMemo(() => {
    if (!nextTherapySession) {
      return false
    }

    return nextTherapySession.status === 'ONGOING'
  }, [nextTherapySession])

  const { open } = useModals()
  const openReferralModal = useCallback(() => {
    open('referralShare')
  }, [open])

  useEffect(() => {
    const openDialog = searchParams.get('open-dialog')

    if (openDialog === 'member-get-member') {
      openReferralModal()

      searchParams.delete('open-dialog')
      setSearchParams(searchParams)
    }
  }, [openReferralModal, searchParams, setSearchParams])

  return (
    <>
      <PageLayout $backgroundColorName="lighter" maxWidth="1260px">
        {!loading && <PushNotificationRequest />}
        {!loading && <AskForReview />}
        <AgendaPollingEffect startPolling={startPolling} stopPolling={stopPolling} />
        <CreatePartnerSuggestionBanner />
        <CreateLegalGuardiansSuggestionBanner />
        {!loading && !!nextTherapySession && showPaymentMethodWarning && (
          <Flex $pb={SPACING_MD}>
            <PaymentMethodWarningSuggestion
              id={nextTherapySession.id}
              startAt={nextTherapySession.startAt}
              status={nextTherapySession.status}
            />
          </Flex>
        )}
        {!loading && !!nextTherapySession && showLiveTherapySessionWarning && (
          <Flex $pb={SPACING_MD}>
            <LiveTherapySessionSuggestion provider={nextTherapySession.provider} url={nextTherapySession.url} />
          </Flex>
        )}

        <Flex $direction="row" $justify="space-between">
          <WelcomeHeader firstName={firstName} />
          {showReferralCode && !isSmallOnly && <LinkMemberGetMember />}
        </Flex>

        {showMemberGetMember && <MemberGetMemberSuggestion />}
        {!therapies.length && (
          <Flex $pt={SPACING_MD}>
            <TherapyBanner />
          </Flex>
        )}
        {!!therapies.length && <NextTherapySessionsModuleTitle />}
        <Flex $pt={SPACING_MD}>
          {error && (
            <Text colorName="error" fontWeight="600" kind="caption">
              <Translation id="generic.errorOccurred.title" />
            </Text>
          )}
          {!therapies.length && (
            <>
              <Flex $pb={SPACING_XS}>
                <StartTherapyPath />
              </Flex>

              {showReferralCode && (
                <Flex $pt={SPACING_XS}>
                  <SettingsInviteCard />
                </Flex>
              )}
            </>
          )}
          {!loading && !!therapies.length && !agenda?.nextTherapySessions?.length && (
            <>
              <Flex $pb={SPACING_XS}>
                <AgendaPatientEmptyState />
              </Flex>

              {showReferralCode && (
                <Flex $pt={SPACING_XS}>
                  <SettingsInviteCard />
                </Flex>
              )}
            </>
          )}

          {!loading && !!agenda?.nextTherapySessions?.length && (
            <>
              <Flex key={agenda.nextTherapySessions[0].id} $pb={SPACING_XS}>
                <TherapySessionCardPatient {...agenda.nextTherapySessions[0]} isVeryNextTherapySession={true} />
              </Flex>

              {showReferralCode && (
                <Flex $pt={SPACING_XS}>
                  <SettingsInviteCard />
                </Flex>
              )}

              {agenda.nextTherapySessions.slice(1, 3).map((ts) => (
                <Flex key={ts.id} $pb={SPACING_XS} $pt={SPACING_MD}>
                  <TherapySessionCardPatient {...ts} isVeryNextTherapySession={false} />
                </Flex>
              ))}
            </>
          )}
        </Flex>
        {!sentenceOfTheDayLoading && (
          <Flex $pt={SPACING_LG}>
            <Text kind="h2">
              <Translation id="home.patient.journaling.title" />
            </Text>

            <Flex $justify="space-between" $mdDirection="row" $pt={SPACING_MD}>
              <Flex $mdBasis={leftMdBasis}>
                <JournalingCard />
              </Flex>

              <Flex $mdHide $pt={SPACING_MD}>
                <StickerModule />
              </Flex>

              <Flex $mdBasis={rightMdBasis} $mdPt={0} $pt={SPACING_MD}>
                {!!sentenceOfTheDay && language === 'it' ? (
                  <>
                    <SentencesTutorialIntroductionModal />
                    <LazySentenceOfTheDay kind="paragraph" p={SPACING_MD} showSendButton={isNativeApp} />
                  </>
                ) : (
                  <>
                    <JournalingTutorialSummary />
                    <Flex $pt={SPACING_MD}>
                      <WritingIsGoodCardSmall />
                    </Flex>

                    <JournalingTutorialModal />
                  </>
                )}
              </Flex>
            </Flex>
          </Flex>
        )}
        <Flex $hide $mdHide={false} $pt={SPACING_MD}>
          <StickerModule />
        </Flex>
        <ServiceInfo />
        {!!therapies.length && !isUnderageTherapy && (
          <Flex $pt={SPACING_XL}>
            <StartTherapyPath />
          </Flex>
        )}
      </PageLayout>

      <MobileHookBanner />
    </>
  )
}
