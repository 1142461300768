import { type PropsWithChildren, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { CarouselProvider } from '~/components/Carousel'
import { usePatientTherapiesQuery } from '~/domains/patient/hooks/usePatientTherapiesQuery'
import { getRoute } from '~/utils/getRoute'

type PathsProviderProps = PropsWithChildren

export const PathsProvider = ({ children }: PathsProviderProps) => {
  const history = useHistory()
  const { therapies } = usePatientTherapiesQuery()
  const length = Math.max(therapies.length, 1) + 1

  const handleOnSetCurrent = useCallback(
    (index: number) => {
      if (index === length - 1) {
        history.replace(getRoute('/paths/new'))

        return
      }

      const therapy = therapies[index]

      if (therapy != null) {
        history.replace(getRoute(`/paths/${therapy.id}`))

        return
      }
    },
    [history, length, therapies],
  )

  return (
    <CarouselProvider length={length} onSetCurrent={handleOnSetCurrent}>
      {children}
    </CarouselProvider>
  )
}
