import { useTranslation } from '~/i18n/hooks/useTranslation'
import { type TranslationValues } from '~/i18n/types'

export type PageKey =
  | 'availabilities.calendar'
  | 'availabilities.weekly'
  | 'booking.calendar'
  | 'booking.thanks'
  | 'bundle'
  | 'changeTherapist'
  | 'chat'
  | 'clinicalTest'
  | 'communications'
  | 'diaries'
  | 'foodJournal'
  | 'forgotPassword'
  | 'historicalTherapySessions'
  | 'home'
  | 'invoices'
  | 'journaling.benefits'
  | 'journaling.log.edit'
  | 'journaling.log.share'
  | 'journaling.log.shared'
  | 'journaling.log'
  | 'journaling.logs'
  | 'journaling.settings.activities'
  | 'journaling.settings.goalInDays'
  | 'journaling.settings.notifications'
  | 'journaling.settings'
  | 'journaling.tipsOfLove.activityCheck'
  | 'journaling.tipsOfLove.moods'
  | 'journaling.tipsOfLove.score'
  | 'journaling.tipsOfLove.success'
  | 'journaling.tipsOfLove.thankfulReasons'
  | 'journaling.tipsOfLove.todayThoughts'
  | 'journaling'
  | 'login'
  | 'notifications.journaling'
  | 'notifications'
  | 'onboarding.couples'
  | 'onboarding.guardian'
  | 'onboarding'
  | 'paths'
  | 'payments.billing'
  | 'payments.confirm'
  | 'payments'
  | 'plan'
  | 'productReview.detail'
  | 'profile.education'
  | 'referral'
  | 'reservation.booking'
  | 'reservation.checkVerificationCode'
  | 'reservation.noMatchedTherapists'
  | 'reservation.paymentMethod'
  | 'reservation.preBooking'
  | 'reservation.sendVerificationCode'
  | 'reservation.skipBooking'
  | 'reservation.skipBookingCoaching'
  | 'reservation.skipBookingPsychiatry'
  | 'reservation.skipBookingWithTherapy'
  | 'reservation.submit'
  | 'reservation.thankYouPage'
  | 'reservation.therapyAlreadyOngoing'
  | 'resetPassword'
  | 'rfrrl'
  | 'saas.onboardingInternal'
  | 'sentences.share'
  | 'settings.account'
  | 'settings.activities'
  | 'settings.billing'
  | 'settings.invite'
  | 'settings.languages'
  | 'settings.pathologies'
  | 'settings.patientTypes'
  | 'settings.payments.add'
  | 'settings.payments.confirm'
  | 'settings.payments'
  | 'settings.plans'
  | 'settings.professionalSpecializations'
  | 'settings.styles'
  | 'settings.techniques'
  | 'settings.workplaces'
  | 'settings'
  | 'signup'
  | 'start.thanks'
  | 'start'
  | 'stickers'
  | 'therapies'
  | 'therapist.patientsManagement'
  | 'therapist.profile'
  | 'therapy-session.booking'
  | 'therapy-session.delete'
  | 'therapy-session.edit'
  | 'therapy-session.ended'
  | 'therapy-session.live.external'
  | 'therapy-session.live'
  | 'therapy-session.schedule'
  | 'token'
  | 'verify.email'
  | 'videocall.troubleshooting'

const seoDescriptions: Extract<PageKey, 'login' | 'signup' | 'start'>[] = ['login', 'signup', 'start']

type SeoDescriptionOverride = (typeof seoDescriptions)[number]

export const useSeoMeta = (pageKey: PageKey, values?: TranslationValues) => {
  const descriptionKey = seoDescriptions.includes(pageKey as SeoDescriptionOverride)
    ? (pageKey as SeoDescriptionOverride)
    : 'home'

  return {
    description: useTranslation(`seo.${descriptionKey}.description`, values),
    title: `${useTranslation(`seo.${pageKey}.title`, values)} - Serenis`,
  }
}
