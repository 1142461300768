import { Flex } from 'cdk'
import { type ReactNode } from 'react'
import { Card, Text } from 'ui'
import { Translation } from '~/components/Translation'

type Props = {
  children?: ReactNode
}

export const AgendaPatientEmptyStateCard = ({ children }: Props) => (
  <Card $kind="placeholder">
    <Flex $align="center">
      <Text colorName="neutral-80" kind="h3" textAlign="center">
        <Translation id="agenda.noTherapySessionsBooked.title" />
      </Text>
    </Flex>

    {children}
  </Card>
)
