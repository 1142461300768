import { type FlexProps, OverflowAuto } from 'cdk'
import { COLOR_LIGHTER } from 'design-tokens'
import { type PropsWithChildren, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

const SHADOW_SIZE = 24

type ContainerProps = {
  $isAfter: boolean
  $isBefore: boolean
  $offsetAfter: number
  $offsetBefore: number
}

const Container = styled(OverflowAuto)<ContainerProps>`
  &::after,
  &::before {
    content: '';
    position: absolute;
    right: 0;
    left: 0;
    height: ${SHADOW_SIZE}px;
    pointer-events: none;
  }

  ${({ $isAfter, $offsetAfter }) =>
    $isAfter &&
    `&::after { 
      bottom: calc(0px + ${$offsetAfter}px);
      box-shadow: inset 0 -26px 20px -13px rgb(from ${COLOR_LIGHTER} r g b / 66%);
    }`}

  ${({ $isBefore, $offsetBefore }) =>
    $isBefore &&
    `&::before { 
      top: calc(0px + ${$offsetBefore}px);
      box-shadow: inset 0 26px 20px -13px rgb(from ${COLOR_LIGHTER} r g b / 66%);
    }`}
`

type Props = PropsWithChildren<
  {
    offsetAfter?: number
    offsetBefore?: number
  } & FlexProps
>

export const OverflowAutoWithShadow = ({ offsetAfter = 0, offsetBefore = 0, children, ...props }: Props) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [showShadowTop, setShowShadowTop] = useState(false)
  const [showShadowBottom, setShowShadowBottom] = useState(false)

  useEffect(() => {
    const checkForScrollbar = () => {
      if (containerRef.current == null) {
        return null
      }

      const { scrollHeight, clientHeight, scrollTop } = containerRef.current

      if (containerRef.current) {
        setShowShadowTop(scrollTop > SHADOW_SIZE)
        setShowShadowBottom(scrollHeight - clientHeight > scrollTop)
      }
    }

    checkForScrollbar()

    window.addEventListener('resize', checkForScrollbar)
    window.addEventListener('wheel', checkForScrollbar, { passive: true })

    return () => {
      window.removeEventListener('resize', checkForScrollbar)
      window.removeEventListener('wheel', checkForScrollbar)
    }
  }, [])

  return (
    <Container
      {...props}
      ref={containerRef}
      $isAfter={showShadowBottom}
      $isBefore={showShadowTop}
      $offsetAfter={offsetAfter}
      $offsetBefore={offsetBefore}
    >
      {children}
    </Container>
  )
}
