const routes = ['/login'] as const

const matchPath = (path: string, route: string) => {
  const pathSegments = path.split('/')
  const routeSegments = route.split('/')

  if (pathSegments.length !== routeSegments.length) {
    return false
  }

  return pathSegments.every((segment, i) => routeSegments[i] === segment || routeSegments[i].startsWith(':'))
}

export const isNextJsRoute = (pathname: string) => routes.some((route) => matchPath(pathname, route))

type RouteParams<Path extends string> = Path extends `:${infer Param}/${infer Rest}`
  ? { [k in Param | keyof RouteParams<Rest>]: string | number }
  : Path extends `:${infer Param}`
    ? { [k in Param]: string }
    : Path extends `${infer _}:${infer Rest}`
      ? { [k in keyof RouteParams<`:${Rest}`>]: string | number }
      : never

type Route = (typeof routes)[number]

type RouteWithParams = Extract<Route, `${string}:${string}`>
type RouteWithoutParams = Exclude<Route, RouteWithParams>

export function getPath<R extends RouteWithoutParams>(route: R): string
export function getPath<R extends Route>(route: R, params: RouteParams<R>): string
export function getPath<R extends string>(route: R, params?: RouteParams<R>) {
  if (params == null) {
    return route
  }

  return route
    .split('/')
    .map((part) => {
      if (part.startsWith(':')) {
        return params[part.slice(1)]
      }

      return part
    })
    .join('/')
}
