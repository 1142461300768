import { gql, useQuery } from '@apollo/client'
import {
  type UserFormFlowResponseCompletedQuery,
  type UserFormFlowResponseCompletedQueryVariables,
} from '~/types/graphql'

const QUERY = gql`
  query userFormFlowResponseCompleted {
    userFormFlowResponseCompleted {
      id
      userId
      isUnderage
    }
  }
`

export const useUserFormFlowResponseCompleted = () => {
  const { data, error, loading } = useQuery<
    UserFormFlowResponseCompletedQuery,
    UserFormFlowResponseCompletedQueryVariables
  >(QUERY)

  return {
    error: !!error,
    loading,
    userFormFlowResponseCompletedId: data?.userFormFlowResponseCompleted?.id,
    userFormFlowResponseCompletedUserId: data?.userFormFlowResponseCompleted?.userId,
    isUnderage: data?.userFormFlowResponseCompleted?.isUnderage,
  }
}
