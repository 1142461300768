import { Flex, Pressable } from 'cdk'
import { SPACING_4XS } from 'design-tokens'
import { useCallback } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { Card, Text } from 'ui'
import { CenteredLoader } from '~/components/CenteredLoader'
import { Translation } from '~/components/Translation'
import { type ClinicalTestCode } from '~/types/graphql'
import { ClinicalTestNameTranslation } from '../components/ClinicalTestNameTranslation'
import { useClinicalTests } from '../hooks/useClinicalTests'
import { type FormValues } from './AttachClinicalTestToChat'
import { getAttachClinicalTestToChatRoute } from './getRoute'

type ReactHookFormClinicalTestProp = {
  code: ClinicalTestCode
  id: string
  onClick: () => void
}

const ReactHookFormClinicalTest = ({ code, id, onClick }: ReactHookFormClinicalTestProp) => {
  const { control, setValue } = useFormContext<FormValues>()

  const handleClick = useCallback(() => {
    setValue('clinicalTestId', id)

    onClick()
  }, [id, onClick, setValue])

  return (
    <Controller
      control={control}
      name="clinicalTestId"
      render={() => (
        <Pressable onClick={handleClick}>
          <Card $gap={SPACING_4XS}>
            <Text fontWeight="600" kind="paragraph">
              <ClinicalTestNameTranslation code={code} />
            </Text>
            <Text colorName="neutral-70" kind="caption">
              {code}
            </Text>
          </Card>
        </Pressable>
      )}
    />
  )
}

export const ChooseClinicalTestRoute = () => {
  const history = useHistory()
  const { clinicalTests, loading } = useClinicalTests()

  const handleClick = useCallback(() => {
    history.push(getAttachClinicalTestToChatRoute('/message'))
  }, [history])

  if (loading) {
    return <CenteredLoader />
  }

  return (
    <Flex $gap={8}>
      <Text kind="h3">
        <Translation id="clinicalTests.choose.title" />
      </Text>
      <Flex $gap={12}>
        {clinicalTests.map(({ code, id }) => (
          <ReactHookFormClinicalTest key={id} code={code} id={id} onClick={handleClick} />
        ))}
      </Flex>
    </Flex>
  )
}
