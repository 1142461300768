export const GLOBAL_VALUE_SEPARATOR = '$$$'

export const THERAPY_SESSION_COST_ONLINE_COUPLE_FIRST = 0
export const THERAPY_SESSION_COST_ONLINE_COUPLE = 5900
export const THERAPY_SESSION_COST_ONLINE_MYSELF_FIRST = 0
export const THERAPY_SESSION_COST_ONLINE_MYSELF = 4900
export const THERAPY_SESSION_COST_ONLINE_COACHING_FIRST = 0
export const THERAPY_SESSION_COST_ONLINE_COACHING = 5500
export const THERAPY_SESSION_COST_ONLINE_DCA_FIRST = 7700
export const THERAPY_SESSION_COST_ONLINE_DCA = 5500
export const THERAPY_SESSION_COST_ONLINE_PSYCHIATRY_FIRST = 8900
export const THERAPY_SESSION_COST_ONLINE_PSYCHIATRY = 7700
export const THERAPY_SESSION_COST_ONLINE_SEXOLOGY_FIRST = 0
export const THERAPY_SESSION_COST_ONLINE_SEXOLOGY = 4900
export const THERAPY_SESSION_COST_ONLINE_WEIGHT_LOSS_FIRST = 7700
export const THERAPY_SESSION_COST_ONLINE_WEIGHT_LOSS = 4000

export const THERAPY_SESSION_JOINING_TIME_WINDOW_BEFORE_START_AT_IN_MINUTES = 15
export const THERAPY_SESSION_JOINING_TIME_WINDOW_AFTER_END_AT_IN_MINUTES = 60
