import { Flex, media, MountOn, PositionSticky } from 'cdk'
import {
  SPACING_0,
  SPACING_2XL,
  SPACING_3XS,
  SPACING_4XL,
  SPACING_LG,
  SPACING_MD,
  SPACING_SM,
  SPACING_XL,
  SPACING_XS,
} from 'design-tokens'
import { Icon } from 'icons'
import { toCurrencyIntOrDecimal } from 'numbers'
import { useMemo } from 'react'
import styled from 'styled-components'
import { Text } from 'ui'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { GLOBAL_VALUE_SEPARATOR } from '~/constants'
import { type EventViewId, useTrackEventView } from '~/domains/analytics/hooks/useTrackEventView'
import { useConventionCodeName } from '~/domains/convention/hooks/useConventionCodeName'
import { useIsUtmSource } from '~/hooks/useIsUtmSource'
import { usePartnershipQueryParam } from '~/hooks/usePartnershipQueryParam'
import { type TranslationId, type TranslationValues } from '~/i18n/types'
import { getTherapySessionCostsByIntent } from '~/utils/getTherapySessionCostsByIntent'
import { getLocalStorage } from '~/utils/localStorage/getLocalStorage'
import { type Intent, useSignupIntent } from '../hooks/useSignupIntent'
import { useSignupWish, type Wish } from '../hooks/useSignupWish'
import { TrustpilotWidget } from './TrustpilotWidget'

const titleByIntentAndWish: Record<Intent, Record<Wish, TranslationId>> = {
  call: {
    booking: 'signup.booking.title',
    calendar: 'signup.calendar.title',
    comparing: 'signup.comparing.title',
    curriculum: 'signup.curriculum.title',
    default: 'signup.call.title',
  },
  dca: {
    booking: 'signup.dca.title',
    calendar: 'signup.dca.title',
    comparing: 'signup.dca.title',
    curriculum: 'signup.dca.title',
    default: 'signup.dca.title',
  },
  journaling: {
    booking: 'signup.booking.title',
    calendar: 'signup.calendar.title',
    comparing: 'signup.comparing.title',
    curriculum: 'signup.curriculum.title',
    default: 'signup.journaling.title',
  },
  home: {
    booking: 'signup.booking.title',
    calendar: 'signup.calendar.title',
    comparing: 'signup.comparing.title',
    curriculum: 'signup.curriculum.title',
    default: 'signup.home.title',
  },
  nutritionist: {
    booking: 'signup.booking.title',
    calendar: 'signup.calendar.title',
    comparing: 'signup.comparing.title',
    curriculum: 'signup.curriculum.title',
    default: 'signup.nutritionist.title',
  },
  coaching: {
    booking: 'signup.booking.title',
    calendar: 'signup.calendar.title',
    comparing: 'signup.comparing.title',
    curriculum: 'signup.curriculum.title',
    default: 'signup.title',
  },
  psychiatry: {
    booking: 'signup.title.psychiatry',
    calendar: 'signup.title.psychiatry',
    comparing: 'signup.title.psychiatry',
    curriculum: 'signup.title.psychiatry',
    default: 'signup.title.psychiatry',
  },
  sexology: {
    booking: 'signup.booking.title',
    calendar: 'signup.calendar.title',
    comparing: 'signup.comparing.title',
    curriculum: 'signup.curriculum.title',
    default: 'signup.title',
  },
  therapyMyself: {
    booking: 'signup.booking.title',
    calendar: 'signup.calendar.title',
    comparing: 'signup.comparing.title',
    curriculum: 'signup.curriculum.title',
    default: 'signup.title',
  },
  therapyCouple: {
    booking: 'signup.booking.title',
    calendar: 'signup.calendar.title',
    comparing: 'signup.comparing.title',
    curriculum: 'signup.curriculum.title',
    default: 'signup.title',
  },
}

const itemsByIntentAndWisth: Record<Intent, Record<Wish, TranslationId[] | null>> = {
  call: { booking: null, calendar: null, comparing: null, curriculum: null, default: null },
  dca: {
    booking: [
      'signup.hero.item1.booking',
      'signup.hero.variant.item2.nutrition',
      'signup.hero.variant.item3.nutrition',
    ],
    calendar: [
      'signup.hero.item1.calendar',
      'signup.hero.variant.item2.nutrition',
      'signup.hero.variant.item3.nutrition',
    ],
    comparing: [
      'signup.hero.item1.comparing',
      'signup.hero.variant.item2.nutrition',
      'signup.hero.variant.item3.nutrition',
    ],
    curriculum: [
      'signup.hero.item1.curriculum',
      'signup.hero.variant.item2.nutrition',
      'signup.hero.variant.item3.nutrition',
    ],
    default: [
      'signup.hero.variant.item1.nutrition',
      'signup.hero.variant.item2.nutrition',
      'signup.hero.variant.item3.nutrition',
    ],
  },
  journaling: { booking: null, calendar: null, comparing: null, curriculum: null, default: null },
  home: { booking: null, calendar: null, comparing: null, curriculum: null, default: null },
  nutritionist: {
    booking: [
      'signup.hero.item1.booking',
      'signup.hero.variant.item2.nutrition',
      'signup.hero.variant.item3.nutrition',
    ],
    calendar: [
      'signup.hero.item1.calendar',
      'signup.hero.variant.item2.nutrition',
      'signup.hero.variant.item3.nutrition',
    ],
    comparing: [
      'signup.hero.item1.comparing',
      'signup.hero.variant.item2.nutrition',
      'signup.hero.variant.item3.nutrition',
    ],
    curriculum: [
      'signup.hero.item1.curriculum',
      'signup.hero.variant.item2.nutrition',
      'signup.hero.variant.item3.nutrition',
    ],
    default: [
      'signup.hero.variant.item1.nutrition',
      'signup.hero.variant.item2.nutrition',
      'signup.hero.variant.item3.nutrition',
    ],
  },
  coaching: {
    booking: ['signup.hero.item1.booking', 'signup.hero.item2.booking', 'signup.hero.item3.coaching'],
    calendar: ['signup.hero.item1.calendar', 'signup.hero.item2.calendar', 'signup.hero.item3.coaching'],
    comparing: ['signup.hero.item1.comparing', 'signup.hero.item2.comparing', 'signup.hero.item3.coaching'],
    curriculum: ['signup.hero.item1.curriculum', 'signup.hero.item2.curriculum', 'signup.hero.item3.coaching'],
    default: ['signup.hero.item1', 'signup.hero.item2', 'signup.hero.item3.coaching'],
  },
  psychiatry: {
    booking: null,
    calendar: null,
    comparing: null,
    curriculum: null,
    default: [
      'signup.hero.variant.item1.psychiatry',
      'signup.hero.variant.item2.psychiatry',
      'signup.hero.variant.item3.psychiatry',
    ],
  },
  sexology: {
    booking: ['signup.hero.item1.booking', 'signup.hero.item2.booking', 'signup.hero.item3.sexology'],
    calendar: ['signup.hero.item1.calendar', 'signup.hero.item2.calendar', 'signup.hero.item3.sexology'],
    comparing: ['signup.hero.item1.comparing', 'signup.hero.item2.comparing', 'signup.hero.item3.sexology'],
    curriculum: ['signup.hero.item1.curriculum', 'signup.hero.item2.curriculum', 'signup.hero.item3.sexology'],
    default: ['signup.hero.item1', 'signup.hero.item2', 'signup.hero.item3.sexology'],
  },
  therapyMyself: {
    booking: ['signup.hero.item1.booking', 'signup.hero.item2.booking', 'signup.hero.item3.booking'],
    calendar: ['signup.hero.item1.calendar', 'signup.hero.item2.calendar', 'signup.hero.item3.calendar'],
    comparing: ['signup.hero.item1.comparing', 'signup.hero.item2.comparing', 'signup.hero.item3.comparing'],
    curriculum: ['signup.hero.item1.curriculum', 'signup.hero.item2.curriculum', 'signup.hero.item3.curriculum'],
    default: ['signup.hero.item1', 'signup.hero.item2', 'signup.hero.item3'],
  },
  therapyCouple: {
    booking: ['signup.hero.item1.booking', 'signup.hero.item2.therapyCouple', 'signup.hero.item3.booking'],
    calendar: ['signup.hero.item1.calendar', 'signup.hero.item2.therapyCouple', 'signup.hero.item3.calendar'],
    comparing: ['signup.hero.item1.comparing', 'signup.hero.item2.therapyCouple', 'signup.hero.item3.comparing'],
    curriculum: ['signup.hero.item1.curriculum', 'signup.hero.item2.therapyCouple', 'signup.hero.item3.curriculum'],
    default: ['signup.hero.item1', 'signup.hero.item2.therapyCouple', 'signup.hero.item3'],
  },
}

const FlexWithoutMargin = styled(Flex)`
  margin: 0;
`

const PositionStickyWithLgTop = styled(PositionSticky)`
  ${media.gtMd`
    top: 0;
  `}
`

type Props = {
  translationId: TranslationId
  translationValues: TranslationValues
}

const ListItem = ({ translationId, translationValues }: Props) => (
  <Flex $align="center" $direction="row" $gap={SPACING_3XS} $grow={1} $shrink={0}>
    <Flex $shrink={0}>
      <Icon colorName="white" name="srns-check" size={16} />
    </Flex>

    <Flex $grow={1} $mdShrink={0} $shrink={1}>
      <TranslationMarkdown colorName="white" id={translationId} kind="paragraph" values={translationValues} />
    </Flex>
  </Flex>
)

const WISH_TO_EVENT_VIEW_ID: Record<Wish, EventViewId> = {
  booking: 'signup-wish-booking',
  calendar: 'signup-wish-calendar',
  comparing: 'signup-wish-comparing',
  curriculum: 'signup-wish-curriculum',
  default: 'signup-wish-default',
}

export const SignupHeroContent = () => {
  const wish = useSignupWish()
  useTrackEventView(WISH_TO_EVENT_VIEW_ID[wish])

  const intent = useSignupIntent()
  const isWellhubUtmSource = useIsUtmSource('wellhub-srns')
  const conventionCodeName = useConventionCodeName()
  const partnership = usePartnershipQueryParam()
  const [, selectedTherapistName] = (getLocalStorage('pre-booking-selected-therapist') ?? '').split(
    GLOBAL_VALUE_SEPARATOR,
  )
  const { firstTherapySessionCost, therapySessionCost } = getTherapySessionCostsByIntent(intent)

  const titleTranslationId = useMemo((): TranslationId => {
    const titleTranslationId = titleByIntentAndWish[intent][wish] ?? titleByIntentAndWish.therapyMyself.default

    if (
      selectedTherapistName &&
      (titleTranslationId === 'signup.booking.title' ||
        titleTranslationId === 'signup.calendar.title' ||
        titleTranslationId === 'signup.curriculum.title')
    ) {
      switch (wish) {
        case 'booking':
          return 'signup.booking.title.withTherapist'
        case 'calendar':
          return 'signup.calendar.title.withTherapist'
        case 'curriculum':
          return 'signup.curriculum.title.withTherapist'
      }
    }

    return titleTranslationId
  }, [intent, selectedTherapistName, wish])

  const items = useMemo((): TranslationId[] => {
    if (!!conventionCodeName || !!partnership) {
      return [
        'signup.firstSessionFree.ul.convention.1',
        'signup.firstSessionFree.ul.convention.2',
        'signup.firstSessionFree.ul.convention.3',
      ]
    }

    if (isWellhubUtmSource) {
      return ['signup.hero.item1', 'signup.hero.item3']
    }

    return (
      itemsByIntentAndWisth[intent][wish] ??
      itemsByIntentAndWisth[intent].default ??
      itemsByIntentAndWisth.therapyMyself[wish] ??
      itemsByIntentAndWisth.therapyMyself.default ??
      []
    )
  }, [conventionCodeName, intent, isWellhubUtmSource, partnership, wish])

  return (
    <PositionStickyWithLgTop $lgBasis="50%" $maxHeight="100vh">
      <Flex
        $gap={SPACING_SM}
        $grow={1}
        $lgJustify="space-between"
        $lgPx={SPACING_4XL}
        $lgPy={SPACING_2XL}
        $p={SPACING_SM}
        $shrink={1}
      >
        <Flex $gap={SPACING_SM} $lgGap={SPACING_XL}>
          <FlexWithoutMargin $justify="flex-start">
            <Text colorName="white" kind="public-h1">
              <Translation
                id={titleTranslationId}
                values={selectedTherapistName ? { therapistFullName: selectedTherapistName } : {}}
              />
            </Text>
          </FlexWithoutMargin>

          <Flex
            $align="flex-start"
            $direction="row"
            $gap={SPACING_SM}
            $grow={1}
            $lgAlign="center"
            $lgGap={SPACING_LG}
            $lgPb={SPACING_0}
            $pb={intent === 'psychiatry' ? SPACING_MD : SPACING_0}
            $shrink={1}
          >
            <Flex $align="flex-start" $gap={SPACING_XS} $grow={1} $lgGap={SPACING_SM} $shrink={1}>
              {items.map((translationId) => (
                <ListItem
                  key={translationId}
                  translationId={translationId}
                  translationValues={{
                    convention: conventionCodeName || partnership || '',
                    firstTherapySessionCost: toCurrencyIntOrDecimal(firstTherapySessionCost),
                    therapySessionCost: toCurrencyIntOrDecimal(therapySessionCost),
                  }}
                />
              ))}
            </Flex>
          </Flex>

          <MountOn mediaQuery="gt-md">
            <Flex>
              <TranslationMarkdown colorName="white" id="generic.signup.notASubscription" kind="paragraph" />
            </Flex>
          </MountOn>
        </Flex>

        <MountOn mediaQuery="gt-md">
          <Flex $pt={SPACING_LG}>
            <TrustpilotWidget />
          </Flex>
        </MountOn>
      </Flex>
    </PositionStickyWithLgTop>
  )
}
