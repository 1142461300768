import { Flex } from 'cdk'
import { Icon } from 'icons'
import { Card, Text } from 'ui'
import { Translation } from '~/components/Translation'
import { ServiceInfoAction } from './components/ServiceInfoAction'

export const ServiceInfoBillingCard = () => (
  <Flex $pt={16}>
    <Card $mdAlign="center" $mdDirection="row" $p={16}>
      <Flex $align="flex-start" $direction="row" $grow={1} $justify="center" $mdAlign="center" $shrink={1}>
        <Flex $pr={8}>
          <Icon colorName="neutral-80" name="badge-check" size={32} />
        </Flex>

        <Flex $grow={1} $shrink={1}>
          <Text fontWeight="600" kind="paragraph">
            <Translation id="home.patient.serviceInfo.billing.title" />
          </Text>

          <Text kind="paragraph">
            <Translation id="home.patient.serviceInfo.billing.description" />
          </Text>

          <Flex $lgHide>
            <ServiceInfoAction to="/settings/billing" translationId="home.patient.serviceInfo.billing.action" />
          </Flex>
        </Flex>
      </Flex>

      <Flex $hide $lgDirection="row" $lgHide={false}>
        <Flex $lgGrow={1} $lgShrink={1} $pt={4}>
          <Text fontWeight="600" kind="paragraph">
            <ServiceInfoAction to="/settings/billing" translationId="home.patient.serviceInfo.billing.action" />
          </Text>
        </Flex>
      </Flex>
    </Card>
  </Flex>
)
