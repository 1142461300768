import { Flex, OverflowAuto } from 'cdk'
import { useHistory } from 'react-router-dom'
import { Button, useModalContext } from 'ui'
import { Translation } from '~/components/Translation'
import { TabItemContent } from '~/domains/formFlow/components/InfoAndPrices/TabItemContent'
import { therapyPathTypeToFormFlowPrefilledConceptId } from '~/domains/therapies/utils'
import { type TherapyTherapyPathType } from '~/types/graphql'
import { getRoute } from '~/utils/getRoute'

type Props = {
  therapyPathType: TherapyTherapyPathType
}

export const StartTherapyPathModalContent = ({ therapyPathType }: Props) => {
  const history = useHistory()
  const { setIsOpen } = useModalContext()

  const handleGoBack = () => {
    setIsOpen(false)
  }

  const handleGoNext = () => {
    history.push(getRoute(`/start/p/${therapyPathTypeToFormFlowPrefilledConceptId[therapyPathType]}`))
  }

  return (
    <>
      <OverflowAuto data-test-id={`start-therapy-path-${therapyPathType.toLowerCase()}-modal`}>
        <TabItemContent therapyPathType={therapyPathType} />
      </OverflowAuto>

      <Flex $align="center" $direction="row" $justify="flex-end" $pt={16}>
        <Button kind="tertiary" onClick={handleGoBack} type="button">
          <Translation id="actions.goBack" />
        </Button>
        <Flex $px={8} />
        <Flex data-test-id="start-therapy-path-button">
          <Button kind="primary" onClick={handleGoNext} type="button">
            <Translation id="actions.proceed" />
          </Button>
        </Flex>
      </Flex>
    </>
  )
}
