import { assertNever } from 'functions'
import { type TranslationId } from '~/i18n/types'
import { type Therapist, type TherapyTherapyPathType } from '~/types/graphql'

export const getTherapistProfessionTranslationKey = (
  therapyPathType: TherapyTherapyPathType,
  sex: Therapist['sex'],
): Extract<TranslationId, `therapist.jobTitle.${string}`> => {
  switch (therapyPathType) {
    case 'PATH_COACHING':
      return 'therapist.jobTitle.coach'
    case 'COUPLES_PSYCHOTHERAPY':
    case 'MYSELF_PSYCHOTHERAPY':
    case 'PATH_ASSERTIVE_COMMUNICATION':
    case 'PATH_SEXOLOGY':
    case 'PATH_SLEEP':
    case 'UNDERAGE_PSYCHOTHERAPY':
      return sex === 1 ? 'therapist.jobTitle.male' : 'therapist.jobTitle.female'
    case 'MYSELF_PSYCHIATRY':
      return 'therapist.jobTitle.psychiatry'
    case 'PATH_NUTRITION_DCA':
    case 'PATH_NUTRITION_WEIGHT_LOSS':
      return sex === 1 ? 'therapist.jobTitle.nutritionist.male' : 'therapist.jobTitle.nutritionist.female'
    default:
      return assertNever(therapyPathType)
  }
}
