import { Flex, OverflowAuto, PositionAbsolute, PositionRelative } from 'cdk'
import { SPACING_LG, SPACING_MD, SPACING_SM, SPACING_XL } from 'design-tokens'
import { Icon } from 'icons'
import { useCallback } from 'react'
import { Button, Text } from 'ui'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { ActivateConventionErrorIcon } from '~/domains/convention/components/ActivateConventionErrorIcon'
import { useIntercom } from '~/domains/intercom/hooks/useIntercom'
import { useModals } from '~/domains/modals'
import { useCurrentUser } from '~/hooks/useCurrentUser'
import { getLocalStorage } from '~/utils/localStorage/getLocalStorage'
import { removeLocalStorage } from '~/utils/localStorage/removeLocalStorage'

const MODAL_ID = 'referralActivation'

export const ReferralActivationModalError = () => {
  const { referralCode } = useCurrentUser()
  const { close, dismiss } = useModals()
  const { open } = useIntercom()

  const handleModalClose = useCallback(() => {
    removeLocalStorage('referral-code-name')
    dismiss(MODAL_ID)
    close(MODAL_ID)
  }, [close, dismiss])

  const handleOpenChat = useCallback(() => {
    handleModalClose()
    open()
  }, [handleModalClose, open])

  if (referralCode === getLocalStorage('referral-code-name')) {
    handleModalClose()
  }

  return (
    <PositionRelative $gap={SPACING_LG} $pt={SPACING_MD}>
      <PositionAbsolute $right={0} $top={0}>
        <Button isRound kind="tertiary" onClick={handleModalClose} size="sm" type="button">
          <Icon name="srns-xmark-circle" size={24} />
        </Button>
      </PositionAbsolute>

      <OverflowAuto $align="center" $gap={SPACING_LG}>
        <ActivateConventionErrorIcon />

        <Flex $gap={SPACING_XL}>
          <Text colorName="error" kind="h3" textAlign="center">
            <Translation id="referralCode.activation.error.title" />
          </Text>
          <Flex $gap={SPACING_SM}>
            <TranslationMarkdown id="referralCode.activation.error.body" kind="paragraph" textAlign="center" />
          </Flex>
        </Flex>
      </OverflowAuto>

      <Button kind="primary" onClick={handleOpenChat}>
        <Translation id="actions.writeInChat" />
      </Button>
    </PositionRelative>
  )
}
