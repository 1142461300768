import { gql, useSuspenseQuery } from '@apollo/client'
import { type FindTherapyPathByIdQuery, type FindTherapyPathByIdQueryVariables } from '~/types/graphql'
import { useTherapyPathIdLocationParam } from './useTherapyPathIdLocationParam'

const QUERY = gql`
  query findTherapyPathById($id: String) {
    findTherapyPathById(id: $id) {
      active
      cost
      costFirst
      duration
      id
      type
    }
  }
`

export const useSuspenseTherapyPathByIdQuery = (therapyPathId?: string) => {
  const id = useTherapyPathIdLocationParam()

  const { data } = useSuspenseQuery<FindTherapyPathByIdQuery, FindTherapyPathByIdQueryVariables>(QUERY, {
    variables: {
      id: therapyPathId ?? id,
    },
  })

  return {
    therapyPath: data.findTherapyPathById,
  }
}
