import { Flex, Form, MountOn } from 'cdk'
import { SPACING_MD, SPACING_SM, SPACING_XL } from 'design-tokens'
import { useCallback, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { Text } from 'ui'
import { Translation } from '~/components/Translation'
import { useTrackEvent } from '~/domains/analytics/hooks/useTrackEvent'
import { useIntercom } from '~/domains/intercom/hooks/useIntercom'
import { ReactHookFormProvider, useReactHookForm } from '~/domains/react-hook-form'
import { ReferralCode } from '~/domains/referral/components/ReferralCode'
import { useReferralCodeName } from '~/domains/referral/hooks/useReferralCodeName'
import { useFormTranslations } from '~/hooks/useFormTranslations'
import { useLoadingState } from '~/hooks/useLoadingState'
import { useToasts } from '~/hooks/useToasts'
import { type CreatePatientInput } from '~/types/graphql'
import { useSignUp } from '../hooks/useSignUp'
import { useSignupIntent } from '../hooks/useSignupIntent'
import { FloatingButton } from './FloatingButton'
import { SignupFormCTAs } from './SignupFormCTAs'
import { SignupFormEmailField } from './SignupFormEmailField'
import { SignupFormPasswordField } from './SignupFormPasswordField'
import { SignupFormPhoneNumberField } from './SignupFormPhoneNumberField'
import { SignupFormTextField } from './SignupFormTextField'
import { SignupTermsAndConditions } from './SignupTermsAndConditions'
import { TrustpilotWidgetFloating } from './TrustpilotWidgetFloating'

export const SignupForm = () => {
  const { addToast } = useToasts()
  const [errorCount, setErrorCount] = useState<number>(0)
  const { showDefaultLauncher } = useIntercom()
  const referralCode = useReferralCodeName()
  const form = useReactHookForm<CreatePatientInput>({ mode: 'onBlur', reValidateMode: 'onChange' })
  const { startLoading, stopLoading } = useLoadingState()
  const intent = useSignupIntent()
  const signUp = useSignUp(intent)
  const trackFailure = useTrackEvent('FAILURE')

  const {
    errors: { required },
    placeholders,
  } = useFormTranslations()

  const onSubmit = useCallback(
    async (values: CreatePatientInput) => {
      startLoading('local')
      try {
        await signUp({ ...values, referralCode })
      } catch (error) {
        setErrorCount((i) => i + 1)

        /**
         * @description: The third error triggers Intercom visibility. State is updated at next cycle
         */
        if (errorCount > 1) {
          showDefaultLauncher()
        }

        trackFailure({ name: 'failures.auth.signup' })

        addToast({ translationId: 'generic.errorOccurred.title', type: 'alert' })
      } finally {
        stopLoading()
      }
    },
    [startLoading, signUp, referralCode, errorCount, trackFailure, addToast, showDefaultLauncher, stopLoading],
  )

  const { ref, inView } = useInView({
    threshold: 0.5,
  })

  return (
    <ReactHookFormProvider {...form}>
      <Form $grow={1} onSubmit={form.handleSubmit(onSubmit)}>
        {referralCode && (
          <Flex $pb={SPACING_MD}>
            <ReferralCode status="REQUESTED" />
          </Flex>
        )}
        <Flex $grow={1} $justify="space-between">
          <Flex $gap={SPACING_MD}>
            <SignupFormTextField
              autoComplete="given-name"
              label={placeholders.firstName}
              name="firstName"
              rules={{ required }}
              type="text"
            />
            <SignupFormTextField
              autoComplete="family-name"
              label={placeholders.lastName}
              name="lastName"
              rules={{ required }}
              type="text"
            />
            <SignupFormEmailField />
            {intent === 'call' && <SignupFormPhoneNumberField size="small" />}
            <SignupFormPasswordField autoComplete="current-password" name="password" />

            <Flex $gap={SPACING_SM} $pt={8}>
              <Text fontWeight="600" kind="caption">
                <Translation id="forms.labels.consent" />
              </Text>
              <SignupTermsAndConditions />
            </Flex>
          </Flex>

          <Flex ref={ref} $pt={SPACING_XL}>
            <MountOn mediaQuery="lt-lg">
              <TrustpilotWidgetFloating />
            </MountOn>

            <SignupFormCTAs />
          </Flex>
        </Flex>

        <FloatingButton isVisible={!inView} />
      </Form>
    </ReactHookFormProvider>
  )
}
