import { Flex } from 'cdk'
import { format, FormatDateEnum } from 'dates'
import { SPACING_4XS, SPACING_LG, SPACING_SM, SPACING_XS } from 'design-tokens'
import { pipe } from 'fp-ts/function'
import { Icon } from 'icons'
import { Link } from 'react-router-dom'
import { Card, Chip, Loader, Text } from 'ui'
import { Translation } from '~/components/Translation'
import { PatientDetailMaxWidth } from '~/domains/patientsManagement/components/PatientDetailMaxWidth'
import { useTherapistTherapies } from '~/domains/therapies/TherapistTherapiesProvider'
import { type ClinicalTestCode } from '~/types/graphql'
import { ClinicalTestNameTranslation } from '../components/ClinicalTestNameTranslation'
import { useClinicalTestAnswersByTherapyId } from '../hooks/useClinicalTestAnswersByTherapyId'
import { getClinicalTestRoute } from '../utils/getRoute'

type ClinicalTestItemContentProps = {
  code: ClinicalTestCode
  createdAt: Date
}

const ClinicalTestItemContent = ({ code, createdAt }: ClinicalTestItemContentProps) => (
  <Flex $gap={SPACING_4XS}>
    <Text colorName="darker" fontWeight="600" kind="paragraph">
      <ClinicalTestNameTranslation code={code} />
    </Text>
    <Text colorName="neutral-70" kind="caption">
      {code}
    </Text>
    <Text colorName="neutral-70" kind="caption">
      {pipe(createdAt, format(FormatDateEnum.DAY_MONTH_YEAR))}
    </Text>
  </Flex>
)

type ClinicalTestItemProps = ClinicalTestItemContentProps & {
  id: string
  isCompleted: boolean
}

const ClinicalTestItem = ({ code, createdAt, id, isCompleted }: ClinicalTestItemProps) => {
  if (isCompleted) {
    return (
      <Card
        $align="center"
        $direction="row"
        $gap={SPACING_XS}
        $justify="space-between"
        as={Link}
        replace
        to={getClinicalTestRoute(`clinical-tests/${id}`)}
      >
        <ClinicalTestItemContent code={code} createdAt={createdAt} />
        <Chip kind="success">
          <Translation id="generic.completed" />
        </Chip>
      </Card>
    )
  }

  return (
    <Card $align="center" $direction="row" $gap={SPACING_XS} $justify="space-between">
      <ClinicalTestItemContent code={code} createdAt={createdAt} />
    </Card>
  )
}

type PatientDetailClinicalTestsByTherapyIdProps = {
  therapyId: string
}

const PatientDetailClinicalTestsByTherapyId = ({ therapyId }: PatientDetailClinicalTestsByTherapyIdProps) => {
  const { clinicalTests, loading } = useClinicalTestAnswersByTherapyId(therapyId)

  if (loading) {
    return (
      <Flex $align="center" $grow={1} $justify="center" $shrink={1}>
        <Loader colorName="primary" />
      </Flex>
    )
  }

  if (!clinicalTests?.length) {
    return (
      <PatientDetailMaxWidth align="center" mdPt={24} pt={16}>
        <Flex $align="center" $gap={16}>
          <Icon colorName="neutral-60" name="google-docs" size={56} />
          <Text colorName="neutral-60" fontWeight="600" kind="paragraph" textAlign="center">
            <Translation id="clinicalTests.emptyState" />
          </Text>
        </Flex>
      </PatientDetailMaxWidth>
    )
  }

  return (
    <Flex $gap={SPACING_SM} $p={SPACING_LG}>
      {clinicalTests?.map(({ answers, code, createdAt, id }) => (
        <ClinicalTestItem key={id} code={code} createdAt={createdAt} id={id} isCompleted={!!answers.length} />
      ))}
    </Flex>
  )
}

type PatientDetailClinicalTestsProps = {
  patientId: string
}

export const PatientDetailClinicalTests = ({ patientId }: PatientDetailClinicalTestsProps) => {
  const { therapies } = useTherapistTherapies()
  const therapy = therapies.find((therapy) => therapy.patient?.id === patientId)

  if (!therapy) {
    return null
  }

  return <PatientDetailClinicalTestsByTherapyId therapyId={therapy.id} />
}
