import { type PropsWithChildren } from 'react'
import { Redirect } from 'react-router-dom'
import { useUserActingAsGroup } from '~/domains/auth/hooks/useUserActingAsGroup'
import { useFeatureFlagsByUserId } from '~/domains/featureFlags'
import { getRoute } from '~/utils/getRoute'

type HomeGuardProps = PropsWithChildren

export const HomeGuard = ({ children }: HomeGuardProps) => {
  const { isVariant } = useFeatureFlagsByUserId()
  const isPatient = useUserActingAsGroup() === 'patient'

  if (isPatient && isVariant('ff_new_therapies_screen')) {
    return <Redirect to={getRoute('/paths')} />
  }

  return children
}
