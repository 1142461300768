import { Flex } from 'cdk'
import { Link } from 'react-router-dom'
import { Card, Text } from 'ui'
import { Translation } from '~/components/Translation'
import { JournalingLogScoreIllustration } from '~/routes/journalingTipsOfLove/components/JournalingLogScoreIllustration'
import { scores } from '~/routes/journalingTipsOfLove/constants'
import { getRoute } from '~/utils/getRoute'

export const TipsOfLoveCard = () => (
  <Card $grow={1} $p={24} $position="relative" $pt={16} $shrink={1} data-test-id="journaling-tips-card">
    <Flex $grow={1} $justify="space-around">
      <Flex $pr={40}>
        <Text fontWeight="600" kind="paragraph">
          <Translation id="journaling.tipsOfLove.score.title" />
        </Text>
      </Flex>

      <Flex $align="center" $direction="row" $grow={1} $justify="center" $pt={16} $shrink={1}>
        {scores.map((score, index) => (
          <Flex key={score} $pl={index > 0 ? 4 : 0} $shrink={1}>
            <Link to={{ pathname: getRoute('/journaling/tips-of-love'), state: { score } }}>
              <JournalingLogScoreIllustration score={score} size={120} />
            </Link>
          </Flex>
        ))}
      </Flex>
    </Flex>
  </Card>
)
