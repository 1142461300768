import { useEffect } from 'react'
import { getLocalStorage } from '~/utils/localStorage/getLocalStorage'
import { removeLocalStorage } from '~/utils/localStorage/removeLocalStorage'
import { setLocalStorage } from '~/utils/localStorage/setLocalStorage'
import { useTherapySessionLive } from '../../VideocallProvider/useTherapySessionLive'
import { useVideocallEventLogger } from '../useVideocallEventLogger'

const MAX_HISTORY_SIZE = 100
const UNSTABLE_CONNECTION_CHANGES_THRESHOLD = 20

type ConnectionQuality = 'EXCELLENT' | 'MODERATE' | 'POOR'

const estimateConnectionQuality = (downlink: number | null, rtt: number | null): ConnectionQuality | null => {
  if (downlink == null || rtt == null) {
    return null
  }

  if (downlink >= 3 && rtt < 100) {
    return 'EXCELLENT'
  }

  if (downlink >= 1.5 && rtt < 300) {
    return 'MODERATE'
  }

  return 'POOR'
}

export const useNetworkMonitor = () => {
  const { id: therapySessionId } = useTherapySessionLive()
  const logFailure = useVideocallEventLogger()

  useEffect(() => {
    if (!('connection' in navigator) || navigator.connection == null) {
      return
    }

    const lastTherapySessionIdData = getLocalStorage('videocall-network-monitor-id')

    if (therapySessionId !== lastTherapySessionIdData) {
      removeLocalStorage('videocall-network-monitor')
    }

    setLocalStorage('videocall-network-monitor-id', therapySessionId)

    const handleConnectionChange = () => {
      const connection = navigator.connection

      const networkInfo = {
        receivedAt: new Date().toISOString(),
        effectiveType: connection?.effectiveType ?? null,
        downlink: connection?.downlink ?? null,
        downlinkMax: connection?.downlinkMax ?? null,
        rtt: connection?.rtt ?? null,
        saveData: connection?.saveData ?? null,
        type: connection?.type ?? null,
      }
      type NetworkInfo = typeof networkInfo
      const connectionQuality = estimateConnectionQuality(networkInfo.downlink, networkInfo.rtt)

      if (connectionQuality != null && connectionQuality !== 'EXCELLENT') {
        logFailure('videocall.network.suboptimalQuality', new Error(`Connection quality: ${connectionQuality}`))
      }

      if (networkInfo.saveData) {
        logFailure('videocall.network.saveDataEnabled', null)
      }

      const previousData = getLocalStorage('videocall-network-monitor')
      const parsedPreviousData: NetworkInfo[] = previousData != null ? JSON.parse(previousData) : []

      if (parsedPreviousData.length >= UNSTABLE_CONNECTION_CHANGES_THRESHOLD) {
        logFailure('videocall.network.unstable', null)
      }

      const lastNetworkInfo = parsedPreviousData[parsedPreviousData.length - 1]
      const networkInfoChanged =
        lastNetworkInfo == null ||
        Object.entries(networkInfo).some(([key, value]) => value !== lastNetworkInfo[key as keyof NetworkInfo])

      if (!networkInfoChanged || parsedPreviousData.length >= MAX_HISTORY_SIZE) {
        return
      }

      parsedPreviousData.push(networkInfo)

      setLocalStorage('videocall-network-monitor', JSON.stringify(parsedPreviousData))
    }

    navigator.connection.addEventListener('change', handleConnectionChange)

    handleConnectionChange()

    return () => {
      if ('connection' in navigator && navigator.connection != null) {
        navigator.connection.removeEventListener('change', handleConnectionChange)
      }
    }
  }, [logFailure, therapySessionId])
}
