import { Flex } from 'cdk'
import { COLOR_DARKER } from 'design-tokens'
import { fromCents } from 'numbers'
import { type ReactNode } from 'react'
import styled, { type CSSProperties } from 'styled-components'
import { Text } from 'ui'

type PriceProps = {
  additionalInfo?: ReactNode
  amount: number
  color?: CSSProperties['background']
  detail?: ReactNode
}

type PriceWrapperProps = {
  $color?: PriceProps['color']
}

const PriceWrapper = styled(Flex)<PriceWrapperProps>`
  background: ${({ $color }) => $color ?? COLOR_DARKER};
  background-clip: text;
  -webkit-text-fill-color: transparent;
`

export const Price = ({ additionalInfo, amount, color, detail }: PriceProps) => (
  <PriceWrapper $color={color} $maxWidth="100%">
    <Flex $align="center" $direction="row" $gap={4}>
      <Text kind="h0">{fromCents(amount)}</Text>
      <Flex>
        <Flex $direction="row">
          <Text kind="h2">€</Text>
        </Flex>
        {detail !== '' && <Text kind="h3">{detail}</Text>}
      </Flex>
    </Flex>
    {additionalInfo && <Text kind="paragraph">{additionalInfo}</Text>}
  </PriceWrapper>
)
