import { COLOR_PRIMARY_40, COLOR_PRIMARY_50 } from 'design-tokens'
import ContentLoader from 'react-content-loader'
import { Card } from 'ui'

export const PaymentMethodElementSkeleton = () => (
  <Card $backgroundColorName="primary-10">
    <ContentLoader
      backgroundColor={COLOR_PRIMARY_40}
      foregroundColor={COLOR_PRIMARY_50}
      speed={1.5}
      viewBox="0 0 400 24"
    >
      <circle cx="12" cy="12" r="10" />
      <rect height="6" rx="3" ry="3" width="100" x="40" y="8" />
      <rect height="6" rx="3" ry="3" width="160" x="240" y="8" />
    </ContentLoader>
  </Card>
)
