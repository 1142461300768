import { useLocation } from 'react-router-dom'
import { AssertAnonymous } from '~/domains/auth/components/AssertAnonymous'
import { signupIntentShouldRedirectToHome, useSignupIntent } from '~/domains/auth/hooks/useSignupIntent'
import { SignupPage as SignupPageView } from '~/domains/auth/pages/SignupPage'
import { PageNoop } from '~/routes/PageNoop'
import { type Page } from '~/routes/types'
import { type Route } from '~/utils/getRoute'

type LocationState = {
  referrer?: Route
}

export const SignupPage: Page = {
  exact: true,
  id: 'auth.signup',
  paths: ['/signup'],
  options: {
    isVisibleAppBar: false,
    isVisibleBackButton: false,
    isVisibleNotificationsInbox: false,
    isVisibleTabNavigation: false,
    seoKey: 'signup',
    title: null,
  },
  PageGuard: ({ children }) => {
    const { state } = useLocation<LocationState>()
    const intent = useSignupIntent()

    const target: Route = state?.referrer ?? (signupIntentShouldRedirectToHome(intent) ? '/' : '/booking')

    return <AssertAnonymous target={target}>{children}</AssertAnonymous>
  },
  PageLoaded: PageNoop,
  PageProvider: PageNoop,
  PageView: SignupPageView,
}
