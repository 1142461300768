import { Icon, type IconName, type IconSize } from 'icons'
import { type TherapyTherapyPathType } from '~/types/graphql'

type StartTherapyPathIconProps = {
  size: IconSize
  therapyPathType: TherapyTherapyPathType | null
}

const iconByTherapyPathIconVariant: Record<TherapyTherapyPathType, Extract<IconName, `path-${string}-solid`>> = {
  COUPLES_PSYCHOTHERAPY: 'path-couples-solid',
  MYSELF_PSYCHIATRY: 'path-psychiatry-solid',
  MYSELF_PSYCHOTHERAPY: 'path-myself-solid',
  PATH_COACHING: 'path-coaching-solid',
  PATH_NUTRITION_DCA: 'path-nutrition-dca-solid',
  PATH_NUTRITION_WEIGHT_LOSS: 'path-nutrition-weight-loss-solid',
  PATH_SEXOLOGY: 'path-sexology-solid',
  PATH_ASSERTIVE_COMMUNICATION: 'path-myself-solid',
  PATH_SLEEP: 'path-myself-solid',
  UNDERAGE_PSYCHOTHERAPY: 'path-myself-solid',
}

export const StartTherapyPathIcon = ({ size, therapyPathType }: StartTherapyPathIconProps) => {
  const iconName = therapyPathType ? iconByTherapyPathIconVariant[therapyPathType] : null

  if (!iconName) {
    return null
  }

  if (iconName) {
    return <Icon name={iconName} size={size} />
  }
}
