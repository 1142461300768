import { gql, useLazyQuery, useSuspenseQuery } from '@apollo/client'
import * as Sentry from '@sentry/nextjs'
import { type TherapySessionLiveQuery, type TherapySessionLiveQueryVariables } from '~/types/graphql'

// @TODO(comes): https://linear.app/serenis/issue/ENG-965
// Temporary override, fields need to be made non nullable from API
type TherapySessionLive = TherapySessionLiveQuery['therapySessionLive']
type PatchedTherapySessionLive = Omit<TherapySessionLive, 'patient' | 'therapist' | 'therapyPathType'> & {
  patient: NonNullable<TherapySessionLive['patient']>
  therapist: NonNullable<TherapySessionLive['therapist']>
  therapyPathType: NonNullable<TherapySessionLive['therapyPathType']>
}

function assertTherapySessionFields(data: TherapySessionLive): asserts data is PatchedTherapySessionLive {
  if (data.patient == null || data.therapist == null || data.therapyPathType == null) {
    Sentry.captureMessage(`Unexpected nullable fields in therapy session "${data.id}"`, {
      level: 'warning',
      extra: { data },
    })
  }
}

const GET_THERAPY_SESSION_LIVE = gql`
  query therapySessionLive($id: String!, $partnerEmail: String) {
    therapySessionLive(id: $id, partnerEmail: $partnerEmail) {
      endAt
      endAtSlot
      id
      provider
      partner {
        id
        firstName
        fullName
      }
      patient {
        id
        firstName
        fullName
      }
      startAt
      streamToken
      therapyPathType
      therapist {
        id
        firstName
        fullName
        profileImage {
          s
          m
          l
        }
      }
    }
  }
`

type Params = {
  id: string
  partnerEmail?: string | null
}

export const useGetTherapySessionLive = ({ id, partnerEmail }: Params) => {
  const { data } = useSuspenseQuery<TherapySessionLiveQuery, TherapySessionLiveQueryVariables>(
    GET_THERAPY_SESSION_LIVE,
    {
      errorPolicy: 'none',
      fetchPolicy: 'cache-first',
      variables: {
        id,
        partnerEmail: partnerEmail ?? null,
      },
    },
  )

  assertTherapySessionFields(data.therapySessionLive)

  return {
    therapySessionLive: data.therapySessionLive,
  }
}

export const useLazyGetTherapySessionLive = () => {
  const [getTherapySessionLive, { data, error, loading }] = useLazyQuery<
    TherapySessionLiveQuery,
    TherapySessionLiveQueryVariables
  >(GET_THERAPY_SESSION_LIVE, {
    fetchPolicy: 'cache-first',
  })

  return {
    getTherapySessionLive,
    error,
    therapySessionLive: data?.therapySessionLive,
    loading,
  }
}
