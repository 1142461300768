import { Flex } from 'cdk'
import { SPACING_SM } from 'design-tokens'
import { Icon, type IconName } from 'icons'
import { Text } from 'ui'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { useTherapistProfessionTypeValue } from '~/hooks/useTherapistProfessionTypeValue'
import { type TranslationId } from '~/i18n/types'
import { type Therapist } from '~/types/graphql'

type Props = { orientation?: string; yearsOfExperience: number | boolean } & Partial<Therapist>

export const TherapistOverviewCardDetailContentWhatToKnow = ({
  age,
  numberOfPatients,
  orientation,
  therapistWorkplaces,
  workRegion,
  yearsOfExperience,
  sex,
}: Props) => {
  const { isNutritionist } = useTherapistProfessionTypeValue()

  const whatToKnow = [
    !!age && {
      iconName: 'calendar',
      textId: 'therapist.overviewCard.detail.birthYear',
      values: { age },
    },
    !!workRegion && {
      iconName: 'map-pin',
      textId: 'therapist.overviewCard.detail.livesIn',
      values: { region: workRegion },
    },
    !!yearsOfExperience && {
      iconName: 'hourglass',
      textId: 'therapist.overviewCard.detail.yearsOfExperience',
      values: { years: yearsOfExperience },
    },
    !!numberOfPatients && {
      iconName: 'srns-patients',
      textId:
        sex === 1
          ? 'therapist.overviewCard.detail.followedPeople.male'
          : 'therapist.overviewCard.detail.followedPeople.female',
      values: { count: numberOfPatients },
    },
    !!orientation &&
      !isNutritionist && {
        iconName: 'srns-therapy',
        textId: 'therapist.overviewCard.detail.orientation',
        values: { orientation },
      },
    therapistWorkplaces != null &&
      therapistWorkplaces.length > 0 && {
        iconName: 'building',
        textId: 'therapist.overviewCard.detail.workPlaces',
        values: {
          workplaces: therapistWorkplaces
            .map(({ workplace }) => workplace.name)
            .join(', ')
            .toLowerCase(),
        },
      },
  ].filter((v) => !!v) as {
    iconName: IconName
    textId: TranslationId
    values?: any
  }[]

  return (
    <Flex>
      <Text kind="h3">
        <Translation id="therapist.overviewCard.whatYouShouldKnow.title" />
      </Text>
      <Flex $gap={SPACING_SM} $pt={SPACING_SM}>
        {whatToKnow.map(({ iconName, textId, values }) => (
          <Flex key={iconName} $align="flex-start" $direction="row" $gap={SPACING_SM}>
            <Flex>
              <Icon name={iconName} size={24} />
            </Flex>
            <Flex $shrink={1}>
              <TranslationMarkdown colorName="darker" id={textId} values={values} />
            </Flex>
          </Flex>
        ))}
      </Flex>
    </Flex>
  )
}
