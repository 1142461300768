import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Modal, ModalDescription, ModalProvider, ModalTitle, ModalTrigger } from 'ui'
import { Translation } from '~/components/Translation'
import { getRoute } from '~/utils/getRoute'
import { ChooseTherapistBeforeBookingModalContent } from './components/ChooseTherapistBeforeBookingModalContent'

export const ChooseTherapistBeforeBookingModal = () => {
  const history = useHistory()

  const handleRedirect = useCallback(
    (therapyId: string) => {
      history.push(getRoute(`/therapy-session/booking/${therapyId}`))
    },
    [history],
  )

  return (
    <ModalProvider>
      <ModalTrigger>
        <Button kind="primary">
          <Translation id="actions.scheduleNextTherapySession" />
        </Button>
      </ModalTrigger>

      <Modal>
        <ModalTitle>
          <Translation id="agenda.chooseTherapistBeforeBookingModal.title" />
        </ModalTitle>
        <ModalDescription>
          <Translation id="agenda.chooseTherapistBeforeBookingModal.description" />
        </ModalDescription>
        <ChooseTherapistBeforeBookingModalContent handleRedirect={handleRedirect} />
      </Modal>
    </ModalProvider>
  )
}
