import { Flex, MountOn, PositionRelative, Pressable } from 'cdk'
import { SPACING_MD, SPACING_SM_VALUE, SPACING_XS } from 'design-tokens'
import { useBreakpoints } from 'hooks'
import { Icon } from 'icons'
import { type RefObject, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Button, Drawer, DrawerFooter, DrawerTrigger, useDrawerContext } from 'ui'
import { CarouselProvider } from '~/components/Carousel'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { useCarouselSlots } from '~/domains/reservation/hooks/useCarouselSlots'
import { useReservationFlow } from '~/domains/reservation/hooks/useReservationFlow'
import { useSuspenseTherapyPathByIdQuery } from '~/domains/reservation/hooks/useTherapyPathByIdQuery'
import { type ReservationFormValues } from '~/domains/reservation/types'
import { TherapistOverviewCard } from '~/domains/therapist/TherapistOverviewCard'
import { type UserTherapist } from '~/domains/therapist/types'
import { useCurrentUser } from '~/hooks/useCurrentUser'
import { CompareCalendars } from '../common/CompareCalendars'
import { ReservationDurationText } from '../common/ReservationDurationText'
import { ReservationForm } from '../common/ReservationForm'
import { ReservationSubmitButton } from '../common/ReservationSubmitButton'
import { TimeSlotDaysTherapists } from '../TimeSlots/TimeSlotDaysTherapists'

type NavigationElementProps = {
  $dir: 'left' | 'right'
  $show: boolean
}

const NavigationElement = styled(Flex)
  .attrs<NavigationElementProps>(({ $dir, $show }) => ({
    $position: 'absolute',
    $top: '52px',
    $opacity: $show ? 1 : 0,
    $left: ($dir === 'left' && `calc(${SPACING_SM_VALUE} * -1)`) || 'auto',
    $right: ($dir === 'right' && `calc(${SPACING_SM_VALUE} * -1)`) || 'auto',
  }))
  .withConfig({ displayName: 'BookBySuggestedTherapistsOverviewsNavigation' })`
    transition: opacity 0.3s;
    ${({ $show }) =>
      !$show &&
      `
        cursor: default;
        pointer-events: none;
      `}
  `

const Navigation = () => {
  const { goBack, goNext, index, suggestedTherapistsProfiles } = useReservationFlow()

  return (
    <>
      <NavigationElement $dir="left" $show={index > 0}>
        <Pressable onClick={goBack}>
          <Icon colorName="lighter" fillColorName="darker" name="chevron-left-circle" size={24} />
        </Pressable>
      </NavigationElement>

      <NavigationElement $dir="right" $show={index < suggestedTherapistsProfiles.length - 1}>
        <Pressable onClick={goNext}>
          <Icon colorName="lighter" fillColorName="darker" name="chevron-right-circle" size={24} />
        </Pressable>
      </NavigationElement>
    </>
  )
}

const OpenCalendarHeader = () => (
  <Flex $align="center" $direction="row" $justify="space-between">
    <Flex $align="center" $grow={1}>
      <TranslationMarkdown colorName="darker" id="reservation.bookFreeTherapySession" kind="h3" />
    </Flex>
  </Flex>
)

type DrawerCalendarContentProps = {
  compareTherapists: boolean
  initialSelectedSlot?: string | null
  selectedTherapist: UserTherapist | undefined
  suggestedTherapistsProfiles: UserTherapist[]
  toggleCompareTherapists?: VoidFunction | undefined
  onSubmit: ({ selectedTimeSlotWithTherapist }: ReservationFormValues) => void
}

const DrawerCalendarContent = ({
  compareTherapists,
  initialSelectedSlot,
  selectedTherapist,
  suggestedTherapistsProfiles,
  toggleCompareTherapists,
  onSubmit,
}: DrawerCalendarContentProps) => {
  const { close } = useDrawerContext()
  const { length } = useCarouselSlots({ compareTherapists, selectedTherapist, suggestedTherapistsProfiles })

  const { isLarge } = useBreakpoints()

  useEffect(() => {
    if (isLarge) {
      close()
    }
  }, [close, isLarge])

  return (
    <ReservationForm onSubmit={onSubmit}>
      <CarouselProvider length={length}>
        <Flex $gap={SPACING_XS} $grow={1} $pt={SPACING_MD} $shrink={1}>
          <OpenCalendarHeader />
          <TimeSlotDaysTherapists
            compareTherapists={compareTherapists}
            initialSelectedSlot={initialSelectedSlot}
            selectedTherapist={selectedTherapist}
            suggestedTherapistsProfiles={suggestedTherapistsProfiles}
          />

          <DrawerFooter>
            <Flex $gap={SPACING_MD} $grow={1}>
              <ReservationDurationText />

              <Flex $align="center" $direction="row" $gap={SPACING_MD}>
                <Flex $grow={1} $shrink={1}>
                  <Button isGhost kind="primary" onClick={close}>
                    <Translation id="actions.cancel" />
                  </Button>
                </Flex>
                <Flex $grow={1} $shrink={1}>
                  <ReservationSubmitButton />
                </Flex>
              </Flex>

              <CompareCalendars
                alignCenter
                compareTherapists={compareTherapists}
                showBorder
                suggestedTherapistsProfiles={suggestedTherapistsProfiles}
                toggleCompareTherapists={toggleCompareTherapists}
              />
            </Flex>
          </DrawerFooter>
        </Flex>
      </CarouselProvider>
    </ReservationForm>
  )
}

type OpenCalendarProps = {
  compareTherapists: boolean
  initialSelectedSlot?: string | null
  onSubmit: ({ selectedTimeSlotWithTherapist }: ReservationFormValues) => void
  selectedTherapist: UserTherapist | undefined
  suggestedTherapistsProfiles: UserTherapist[]
  toggleCompareTherapists?: VoidFunction | undefined
  triggerRef?: RefObject<HTMLButtonElement>
}

export const TherapistOverviewCardOpenCalendar = ({
  compareTherapists,
  initialSelectedSlot,
  selectedTherapist,
  suggestedTherapistsProfiles,
  toggleCompareTherapists,
  triggerRef,
  onSubmit,
}: OpenCalendarProps) => (
  <MountOn mediaQuery="lt-lg">
    <DrawerTrigger>
      <Flex $grow={1}>
        <Button ref={triggerRef} kind="primary" size="md">
          <Translation id="therapist.overviewCard.cta.showCalendar" />
        </Button>
      </Flex>
    </DrawerTrigger>

    <Drawer>
      <DrawerCalendarContent
        compareTherapists={compareTherapists}
        initialSelectedSlot={initialSelectedSlot}
        onSubmit={onSubmit}
        selectedTherapist={selectedTherapist}
        suggestedTherapistsProfiles={suggestedTherapistsProfiles}
        toggleCompareTherapists={toggleCompareTherapists}
      />
    </Drawer>
  </MountOn>
)

type BookBySuggestedTherapistsOverviewsProps = {
  initialSelectedSlot?: string | null
  onSubmit: ({ selectedTimeSlotWithTherapist }: ReservationFormValues) => void
  toggleCompareTherapists: VoidFunction
}

export const BookBySuggestedTherapistsOverviews = ({
  initialSelectedSlot,
  onSubmit,
  toggleCompareTherapists,
}: BookBySuggestedTherapistsOverviewsProps) => {
  const triggerRef = useRef<HTMLButtonElement>(null)
  const { emailVerified } = useCurrentUser()
  const { autoOpenCalendar, compareTherapists, selectedTherapist, setAutoOpenCalendar, suggestedTherapistsProfiles } =
    useReservationFlow()
  const { therapyPath } = useSuspenseTherapyPathByIdQuery()

  useEffect(() => {
    if (initialSelectedSlot == null || !autoOpenCalendar || !emailVerified) {
      return
    }

    triggerRef.current?.click()
    setAutoOpenCalendar(false)
  }, [autoOpenCalendar, emailVerified, initialSelectedSlot, setAutoOpenCalendar])

  return (
    <PositionRelative $align="center" $maxWidth="fit-content" data-test-id="therapist-overview-card">
      <Flex $maxWidth={350} $minWidth={350}>
        <TherapistOverviewCard
          calendarCTA={
            <TherapistOverviewCardOpenCalendar
              compareTherapists={compareTherapists}
              initialSelectedSlot={initialSelectedSlot}
              onSubmit={onSubmit}
              selectedTherapist={selectedTherapist}
              suggestedTherapistsProfiles={suggestedTherapistsProfiles}
              toggleCompareTherapists={toggleCompareTherapists}
              triggerRef={triggerRef}
            />
          }
          therapyPath={therapyPath.type}
        />
      </Flex>
      <Navigation />
    </PositionRelative>
  )
}
