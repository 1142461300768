import { Flex, type FlexProps } from 'cdk'
import { SPACING_0, SPACING_3XS, SPACING_LG, SPACING_MD, SPACING_SM, SPACING_XS } from 'design-tokens'
import { isNeitherNullNorUndefined } from 'functions'
import { Icon } from 'icons'
import { isNumber } from 'numbers'
import { Avatar, Card, Chip, Text } from 'ui'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { RangeIndicator } from '~/domains/therapist/TherapistOverviewCard/RangeIndicator'
import { TherapistProvider, useTherapist } from '~/hooks/useTherapist'
import { useTherapistFetch } from '~/hooks/useTherapistFetch'
import { useTherapistProfessionTypeValue } from '~/hooks/useTherapistProfessionTypeValue'
import { type TherapyTherapyPathType } from '~/types/graphql'
import { getTherapistProfessionTranslationKey } from '~/utils/getTherapistProfessionTranslationKey'
import { shouldShowYearsOfExperience } from '~/utils/shouldShowYearsOfExperience'
import { TherapistChipSexology } from '../components/TherapistChipSexology'
import { useTherapistYearOfExperience } from '../hooks/useTherapistYearOfExperience'
import { TherapistOverviewCardDetail } from './Detail'
import { OpenResumeCTA } from './OpenResumeCTA'

type HeaderProps = {
  fullName: string
  image: string | undefined
  sex: number
  therapyPath: TherapyTherapyPathType
} & FlexProps

const Header = ({ fullName, image, sex, therapyPath, ...flexProps }: HeaderProps) => (
  <Flex {...flexProps} $align="center" $gap={SPACING_XS}>
    <Avatar image={image} name={fullName} size="2xl" />
    <Flex $align="center" $gap={SPACING_3XS}>
      <Text data-test-id="therapist-full-name" kind="h2">
        {fullName}
      </Text>
      <Text>
        <Translation id={getTherapistProfessionTranslationKey(therapyPath, sex)} />
      </Text>
    </Flex>
  </Flex>
)

type BadgeProps = {
  isSexology: boolean
  registrationRegion: string
  registrationYear: number | null
} & FlexProps

const Badges = ({ isSexology, registrationRegion, registrationYear, ...flexProps }: BadgeProps) => {
  const yearsOfExperience = useTherapistYearOfExperience({ registrationYear })
  const showYearsOfExperience = shouldShowYearsOfExperience(yearsOfExperience)

  return (
    <Flex
      {...flexProps}
      $align="center"
      $direction="row"
      $gap={SPACING_3XS}
      $justify="center"
      $px={SPACING_MD}
      $wrap="wrap"
    >
      {showYearsOfExperience && (
        <Chip kind="info">
          <TranslationMarkdown
            colorName="blue-80"
            fontWeight="500"
            id="therapist.overviewCard.card.yearOfExperience"
            kind="caption"
            values={{ years: yearsOfExperience }}
          />
        </Chip>
      )}
      <Chip kind="neutral">{registrationRegion}</Chip>
      {isSexology && <TherapistChipSexology />}
    </Flex>
  )
}

const Formality = ({ score, ...flexProps }: { score: number | undefined } & FlexProps) => (
  <Flex {...flexProps}>
    <RangeIndicator
      leftIndicator="therapist.overviewCard.style.formality.negative"
      rangeValue={score}
      rightIndicator="therapist.overviewCard.style.formality.positive"
    />
  </Flex>
)

type InformationsProps = {
  age?: number | null
  orientation?: string
  patients: number
  sex: number
  workplaces: { workplace: { id: string; name: string } }[]
} & FlexProps

const Informations = ({ age, orientation, patients, sex, workplaces, ...flexProps }: InformationsProps) => {
  const showPatientsAmount = patients >= 10
  const showWorkplaces = workplaces.length > 0

  const missingInfo = [showPatientsAmount, !showWorkplaces].filter(Boolean).length

  return (
    <Flex {...flexProps} $align="flex-start" $gap={SPACING_SM} $minWidth="100%">
      {showPatientsAmount && (
        <Flex $direction="row" $gap={SPACING_XS}>
          <Flex>
            <Icon name="check" size={16} />
          </Flex>
          <Flex $shrink={1}>
            <TranslationMarkdown
              colorName="darker"
              id={
                sex === 1
                  ? 'therapist.overviewCard.card.followedPeople.male'
                  : 'therapist.overviewCard.card.followedPeople.female'
              }
              values={{ count: patients }}
            />
          </Flex>
        </Flex>
      )}

      <Flex $direction="row" $gap={SPACING_XS}>
        <Flex $pt={SPACING_3XS}>
          <Icon name="check" size={16} />
        </Flex>
        <Flex $shrink={1}>
          <TranslationMarkdown
            colorName="darker"
            id={sex === 1 ? 'therapist.overviewCard.online.title.male' : 'therapist.overviewCard.online.title.female'}
          />
        </Flex>
      </Flex>

      {showWorkplaces && (
        <Flex $direction="row" $gap={SPACING_XS}>
          <Flex $pt={SPACING_3XS}>
            <Icon name="check" size={16} />
          </Flex>
          <Flex $shrink={1}>
            <TranslationMarkdown
              colorName="darker"
              id="therapist.overviewCard.card.workPlaces"
              values={{ workplaces: workplaces.map(({ workplace }) => workplace.name).join(', ') }}
            />
          </Flex>
        </Flex>
      )}

      {missingInfo === 1 && isNeitherNullNorUndefined(orientation) && (
        <Flex $direction="row" $gap={SPACING_XS}>
          <Flex $pt={SPACING_3XS}>
            <Icon name="check" size={16} />
          </Flex>
          <Flex $shrink={1}>
            <TranslationMarkdown
              colorName="darker"
              id="therapist.overviewCard.detail.orientation"
              values={{ orientation }}
            />
          </Flex>
        </Flex>
      )}

      {missingInfo === 2 && isNumber(age) && (
        <Flex $direction="row" $gap={SPACING_XS}>
          <Flex $pt={SPACING_3XS}>
            <Icon name="check" size={16} />
          </Flex>
          <Flex $shrink={1}>
            <TranslationMarkdown colorName="darker" id="therapist.overviewCard.detail.birthYear" values={{ age }} />
          </Flex>
        </Flex>
      )}
    </Flex>
  )
}

type OpenResuneProps = {
  therapyPath: TherapyTherapyPathType
}

const OpenResume = ({ therapyPath }: OpenResuneProps) => (
  <TherapistOverviewCardDetail therapyPath={therapyPath}>
    <OpenResumeCTA />
  </TherapistOverviewCardDetail>
)

type Props = {
  calendarCTA: React.ReactNode
  openResumeCTA?: React.ReactNode
  therapyPath: TherapyTherapyPathType
}

export const TherapistOverviewCard = ({ calendarCTA, openResumeCTA, therapyPath }: Props) => {
  const { fullName, therapist } = useTherapist()

  const { age, numberOfPatients, profileImage, sex, therapistWorkplaces, therapySchool } = therapist
  const { registerRegistrationRegion, registerRegistrationYearPsychologist } = therapist
  const score = therapist.therapistProfessionalStyles?.find((style) => style.type === 'FORMALITY')?.score

  const { isNutritionist } = useTherapistProfessionTypeValue()

  return (
    <Card $grow={1} $maxHeight="fit-content">
      <Header $pt={SPACING_0} fullName={fullName} image={profileImage?.m} sex={sex} therapyPath={therapyPath} />
      <Badges
        $pt={SPACING_SM}
        isSexology={therapyPath === 'PATH_SEXOLOGY'}
        registrationRegion={registerRegistrationRegion}
        registrationYear={registerRegistrationYearPsychologist}
      />
      <Formality $pt={SPACING_MD} score={score} />
      <Informations
        $pt={SPACING_MD}
        age={age}
        orientation={!isNutritionist ? therapySchool?.name : undefined}
        patients={numberOfPatients}
        sex={sex}
        workplaces={therapistWorkplaces ?? []}
      />
      <Flex $gap={SPACING_MD} $pt={SPACING_LG}>
        {calendarCTA}
        {openResumeCTA ?? <OpenResume therapyPath={therapyPath} />}
      </Flex>
    </Card>
  )
}

/**
 * NOTE(@heavybeard): This should be the only one exported in order to remove <TherapistProvider> dependecy
 * @see https://linear.app/serenis/issue/ENG-1194/therapistoverviewcard-shouldnt-depend-from-therapistprovider
 */

type TherapistCardProps = {
  therapistId: string
  therapyPath: TherapyTherapyPathType
}

export const TherapistCard = ({ therapistId, therapyPath }: TherapistCardProps) => {
  const { data, loading } = useTherapistFetch(therapistId)

  if (data?.findTherapistProfileById?.therapist == null || loading) {
    return null
  }

  return (
    <TherapistProvider
      therapist={{
        ...data.findTherapistProfileById,
        therapist: data.findTherapistProfileById.therapist,
      }}
    >
      <TherapistOverviewCard calendarCTA={undefined} therapyPath={therapyPath} />
    </TherapistProvider>
  )
}
