import { FloatingFocusManager, FloatingPortal, useMergeRefs } from '@floating-ui/react'
import { SPACING_MD } from 'design-tokens'
import { forwardRef, type HTMLProps } from 'react'
import styled from 'styled-components'
import { Card } from 'ui'
import { usePopoverContext } from './PopoverContext'

const PopoverContentContainer = styled(Card)`
  width: fit-content;
  margin: 0;
`

type Props = {
  withPadding?: boolean
}

export const PopoverContent = forwardRef<HTMLDivElement, Props & HTMLProps<HTMLDivElement>>(
  ({ children, withPadding = true, ...props }, propRef) => {
    const { context, strategy, y, x, getFloatingProps, isMounted, refs, styles } = usePopoverContext()
    const ref = useMergeRefs([refs.setFloating, propRef])

    if (!isMounted) {
      return null
    }

    return (
      <FloatingPortal>
        <FloatingFocusManager context={context}>
          <PopoverContentContainer
            ref={ref}
            $maxWidth="324px"
            $p={withPadding ? SPACING_MD : 0}
            $zIndex={10}
            {...getFloatingProps({
              ...props,
              style: {
                position: strategy,
                top: y ?? 0,
                left: x ?? 16,
                right: 16,
                ...styles,
                ...props.style,
              },
            })}
          >
            {children}
          </PopoverContentContainer>
        </FloatingFocusManager>
      </FloatingPortal>
    )
  },
)
