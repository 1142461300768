import { Flex, PositionFixed } from 'cdk'
import { COLOR_PRIMARY_80, OPACITY_24, SPACING_MD, SPACING_XS } from 'design-tokens'
import styled, { css } from 'styled-components'
import { useUserActingAsGroup } from '~/domains/auth/hooks/useUserActingAsGroup'
import { therapistNavigationLinks } from '../constants/therapistNavigationLinks'
import { usePatientNavigationLinks } from '../hooks/usePatientNavigationLinks'
import { useTabNavigationMenu } from '../hooks/useTabNavigationMenu'
import { BottomBarLinks } from './BottomBarLinks'
import { LinkMenu } from './custom/LinkMenu'
import { PatientTabMenu } from './PatientTabMenu'
import { TherapistTabMenu } from './TherapistTabMenu'

const BOTTOM_BAR_HEIGHT = '56px'

type BottomProps = {
  $items?: number
}

const BottomBarContainer = styled(Flex).attrs({
  $basis: 1,
  $direction: 'row',
  $grow: 1,
  $justify: 'space-evenly',
  $minHeight: 'auto',
  $p: SPACING_XS,
  $pr: SPACING_MD,
  $pl: SPACING_MD,
})<BottomProps>`
  ${({ $items = 5 }) => css`
    box-shadow: 0 0.5px 0 0 rgb(from ${COLOR_PRIMARY_80} r g b / ${OPACITY_24}) inset;
    transition: all 0.2s;

    & > a,
    & > div {
      min-width: calc(100% / ${$items});
      max-width: calc(100% / ${$items});
    }
  `};
`

export const BottomBar = () => {
  const { open } = useTabNavigationMenu()
  const patientNavigationLinks = usePatientNavigationLinks()

  const isTherapist = useUserActingAsGroup() === 'therapist'
  const links = isTherapist ? therapistNavigationLinks : patientNavigationLinks

  return (
    <>
      <BottomBarContainer as="footer">
        <BottomBarLinks isMenuOpen={open} links={links} />
        <LinkMenu extended={false} isMobile />
      </BottomBarContainer>

      {open && (
        <PositionFixed
          $backgroundColorName="lighter"
          $bottom={BOTTOM_BAR_HEIGHT}
          $left={0}
          $right={0}
          $top={BOTTOM_BAR_HEIGHT}
        >
          {isTherapist ? <TherapistTabMenu /> : <PatientTabMenu />}
        </PositionFixed>
      )}
    </>
  )
}
