import { noop } from 'functions'
import { BrowserRouter } from 'react-router-dom'
import { CompatRouter } from 'react-router-dom-v5-compat'
import { useMixPanelIdentifyEffect } from '~/domains/analytics/hooks/useMixPanelIdentifyEffect'
import { TherapistAvailabilitiesGivenCountProvider } from '~/domains/availabilities/reminders/hooks/useTherapistAvailabilitiesGivenCount'
import { ChatProvider } from '~/domains/chat/hooks/useChat'
import { ChatUnreadCountProvider } from '~/domains/chat/hooks/useChatUnreadCount'
import { useChatUnreadCountListenerEffect } from '~/domains/chat/hooks/useChatUnreadCountListenerEffect'
import { ActivateConventionModal } from '~/domains/convention/components/ActivateConventionModal'
import { FeatureFlagsByFingerprint, FeatureFlagsByUserId } from '~/domains/featureFlags'
import { FormFlowsTextValuesProvider } from '~/domains/formFlow/hooks/useFormFlowTextValues'
import { useUpdateIntercomFeatureFlags } from '~/domains/intercom/hooks/useUpdateIntercomFeatureFlags'
import { Modals } from '~/domains/modals'
import { ModalsProvider } from '~/domains/modals/hooks/useModals'
import { NavigationProvider } from '~/domains/navigation/hooks/useNavigation'
import { ReferralActivationModal } from '~/domains/referral/components/ReferralActivationModal'
import { RootHistoryProvider } from '~/hooks/useRootHistory'
import { MainPageLayout } from '~/Layout/MainPageLayout'

const SetupEffect = () => {
  useChatUnreadCountListenerEffect()
  useMixPanelIdentifyEffect()
  useUpdateIntercomFeatureFlags()

  return null
}

const App = () => (
  <BrowserRouter getUserConfirmation={noop}>
    <CompatRouter>
      <RootHistoryProvider>
        <FormFlowsTextValuesProvider>
          <ModalsProvider>
            <Modals />
            <FeatureFlagsByFingerprint>
              <FeatureFlagsByUserId>
                <ChatUnreadCountProvider>
                  <ChatProvider>
                    <TherapistAvailabilitiesGivenCountProvider>
                      <NavigationProvider>
                        <SetupEffect />
                        <ActivateConventionModal />
                        <MainPageLayout />
                        <ReferralActivationModal />
                      </NavigationProvider>
                    </TherapistAvailabilitiesGivenCountProvider>
                  </ChatProvider>
                </ChatUnreadCountProvider>
              </FeatureFlagsByUserId>
            </FeatureFlagsByFingerprint>
          </ModalsProvider>
        </FormFlowsTextValuesProvider>
      </RootHistoryProvider>
    </CompatRouter>
  </BrowserRouter>
)

export default App
