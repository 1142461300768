import { Flex, PositionAbsolute, PositionRelative } from 'cdk'
import { FONT_WEIGHT_500_VALUE, SPACING_XS, TIME_300 } from 'design-tokens'
import { Icon } from 'icons'
import { useCallback, useState } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { Button, Text } from 'ui'
import { Translation } from '~/components/Translation'
import { useTrackEventClick } from '~/domains/analytics/hooks/useTrackEventClick'
import { useToasts } from '~/hooks/useToasts'
import { type TranslationId } from '~/i18n/types'
import { useLazyGetTherapySessionIcs } from '../hooks/useGetTherapySessionIcs'
import { downloadCalendarEvent } from '../utils/downloadCalendarEvent'
import { getReservationRoutes } from '../utils/getReservationRoutes'

type PositionAbsoluteWithTransitionParams = {
  $show: boolean
}

const PositionAbsoluteWithTransition = styled(PositionAbsolute).attrs<PositionAbsoluteWithTransitionParams>(
  ({ $show }) => ({
    $direction: 'row',
    $grow: 1,
    $opacity: $show ? 1 : 0,
    $zIndex: $show ? 1 : -1,
  }),
)`
  transition: opacity ${TIME_300};
`

type CalendarToast = {
  translationId: TranslationId
  type: 'alert' | 'success'
}

const CALENDAR_TOASTS: Record<'alert' | 'success', CalendarToast> = {
  alert: {
    translationId: 'reservation.downloadCalendarEvent.toast.alert',
    type: 'alert',
  },
  success: {
    translationId: 'reservation.downloadCalendarEvent.toast.success',
    type: 'success',
  },
}

type Props = {
  therapySessionId: string
}

export const DownloadCalendarEventButton = ({ therapySessionId }: Props) => {
  const [showSuccess, setShowSuccess] = useState(false)
  const location = useLocation()

  const toasts = useToasts()
  const trackEventClick = useTrackEventClick()
  const lazyFetch = useLazyGetTherapySessionIcs()

  const handleDownloadCalendarEvent = useCallback(async () => {
    if (location.pathname.includes(getReservationRoutes('/thank-you-page'))) {
      trackEventClick('thank-you-page.download-calendar-event', { therapySessionId })
    }

    const { data } = await lazyFetch({ variables: { id: therapySessionId } })

    const isValidIcs = data?.therapySessionIcs != null

    if (isValidIcs) {
      downloadCalendarEvent({
        base64: data.therapySessionIcs.icsAsBase64,
        fileName: data.therapySessionIcs.fileName,
      })
    }

    toasts.addToast(isValidIcs ? CALENDAR_TOASTS.success : CALENDAR_TOASTS.alert)
    setShowSuccess(isValidIcs)
  }, [lazyFetch, location, therapySessionId, toasts, trackEventClick])

  return (
    <PositionRelative $align="center" $minHeight={40}>
      <PositionAbsoluteWithTransition $show={!showSuccess}>
        <Button isGhost kind="primary" onClick={handleDownloadCalendarEvent}>
          <Flex $direction="row" $gap={SPACING_XS}>
            <Icon name="srns-calendar-plus" size={24} />
            <Translation id="reservation.downloadCalendarEvent.cta" />
          </Flex>
        </Button>
      </PositionAbsoluteWithTransition>

      {showSuccess && (
        <PositionAbsoluteWithTransition $gap={SPACING_XS} $py={SPACING_XS} $show={showSuccess}>
          <Icon colorName="green-60" name="srns-check-circle" size={24} />
          <Text colorName="green-60" fontWeight={FONT_WEIGHT_500_VALUE} kind="paragraph">
            <Translation id="reservation.downloadCalendarEvent.cta.success" />
          </Text>
        </PositionAbsoluteWithTransition>
      )}
    </PositionRelative>
  )
}
