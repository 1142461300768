import { gql, useQuery } from '@apollo/client'
import { type TherapistTherapiesQuery } from '~/types/graphql'

const QUERY = gql`
  query therapistTherapies {
    therapies {
      churnReason
      data {
        hasSituation
        isChurnReasonDefinitive
        isDeletedReasonDefinitive
        missingNotesCounter
        upcomingTherapySessionsCounter
      }
      formFlowResponse {
        id
      }
      hasPsychiatricSharedInformation
      id
      informedConsentsSigned
      ongoing
      isUnderage
      patient {
        id
        fullName
      }
      therapyPath {
        type
      }
    }
  }
`

export const useTherapistTherapiesFetch = () => {
  const { data, error, loading, refetch, updateQuery } = useQuery<TherapistTherapiesQuery>(QUERY)

  return {
    error: error != null,
    loading,
    therapies: data?.therapies ?? [],
    refetch,
    updateQuery,
  }
}
