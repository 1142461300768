import { createFlagsmithInstance } from 'flagsmith'
import { type IInitConfig } from 'flagsmith/types'
import { noop } from 'functions'
import { type FeatureName, type FeatureTrait } from '~/domains/featureFlags'
import { getEnv } from '~/utils/getEnv'

export const config: IInitConfig<FeatureName, FeatureTrait> = {
  api: 'https://edge.api.flagsmith.com/api/v1/',
  cacheFlags: false,
  enableAnalytics: true,
  enableLogs: process.env.NEXT_PUBLIC_ENVIRONMENT === 'development',
  environmentID: getEnv('FLAGSMITH_ENVIRONMENT_ID'),
  onError: noop,
  preventFetch: false,
}

export const flagsmithByFingerprint = createFlagsmithInstance()

export const flagsmithByUserId = createFlagsmithInstance()
