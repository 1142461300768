import { useEffect } from 'react'
import { MemoryRouter, Route, Switch } from 'react-router-dom'
import { Modal, ModalProvider } from 'ui'
import { TrackModalView } from '~/domains/analytics/components/TrackModalView'
import { useCurrentUser } from '~/hooks/useCurrentUser'
import { useEmailVerification } from '../hooks/useEmailVerification'
import { getEmailVerificationRoute } from '../utils/getEmailVerificationRoute'
import { EmailVerificationModalDefaultRoute } from './EmailVerificationModalDefaultRoute'
import { EmailVerificationModalEditRoute } from './EmailVerificationModalEditRoute'

const initialEntries = [getEmailVerificationRoute('/')]

export const EmailVerificationModal = () => {
  const { email, emailVerified } = useCurrentUser()
  const { requestVerification } = useEmailVerification()

  useEffect(() => {
    requestVerification(email)
  }, [email, requestVerification])

  if (emailVerified) {
    return null
  }

  return (
    <ModalProvider isOpen preventClosing>
      <Modal>
        <TrackModalView name="emailVerification" />

        <MemoryRouter initialEntries={initialEntries}>
          <Switch>
            <Route path={getEmailVerificationRoute('/edit')}>
              <EmailVerificationModalEditRoute />
            </Route>

            <Route path={getEmailVerificationRoute('/')}>
              <EmailVerificationModalDefaultRoute />
            </Route>
          </Switch>
        </MemoryRouter>
      </Modal>
    </ModalProvider>
  )
}
