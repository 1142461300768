import { Flex, Form, OverflowAuto } from 'cdk'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Text } from 'ui'
import { Translation } from '~/components/Translation'
import { SignupFormEmailField } from '~/domains/auth/components/SignupFormEmailField'
import { ReactHookFormProvider, useReactHookForm } from '~/domains/react-hook-form'
import { useEmailVerification } from '../hooks/useEmailVerification'
import { getEmailVerificationRoute } from '../utils/getEmailVerificationRoute'

type FormValues = {
  email: string
}

export const EmailVerificationModalEditRoute = () => {
  const { requestVerification, loading } = useEmailVerification()
  const form = useReactHookForm<FormValues>({ mode: 'onBlur' })
  const history = useHistory()

  const onBack = useCallback(() => {
    history.push(getEmailVerificationRoute('/'))
  }, [history])

  const onSubmit = useCallback(
    async ({ email }: FormValues) => {
      const result = await requestVerification(email)
      if (!result) {
        return
      }

      history.push(getEmailVerificationRoute('/'))
    },
    [requestVerification, history],
  )

  return (
    <ReactHookFormProvider {...form}>
      <Form onSubmit={form.handleSubmit(onSubmit)}>
        <OverflowAuto>
          <Text kind="h2">
            <Translation id="emailVerification.edit.title" />
          </Text>

          <Flex $pt={16}>
            <Text kind="paragraph">
              <Translation id="emailVerification.edit.subtitle" />
            </Text>
          </Flex>

          <Flex $gap={16} $pt={16}>
            <SignupFormEmailField />
          </Flex>
        </OverflowAuto>

        <Flex $gap={8} $pt={24}>
          <Button
            disabled={!form.formState.isValid}
            isLoading={form.formState.isSubmitting || loading}
            kind="primary"
            type="submit"
          >
            <Translation id="emailVerification.edit.confirm" />
          </Button>
          <Button isGhost kind="secondary" onClick={onBack}>
            <Translation id="emailVerification.edit.back" />
          </Button>
        </Flex>
      </Form>
    </ReactHookFormProvider>
  )
}
