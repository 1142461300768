import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'
import { usePatientTherapies } from '~/domains/patient/hooks/usePatientTherapies'
import { useTherapist } from '~/hooks/useTherapist'
import { useLanguage } from '~/i18n/hooks/useLanguage'
import { type TherapistSpecializationsQuery, type TherapistSpecializationsQueryVariables } from '~/types/graphql'

const QUERY = gql`
  query therapistSpecializations($therapyId: String) {
    therapistSpecializations(therapyId: $therapyId) {
      id
      name
      nameEn
    }
  }
`

export const useTherapistSpecializations = () => {
  const { language } = useLanguage()
  const { therapies } = usePatientTherapies()
  const { id } = useTherapist()
  const therapy = useMemo(() => therapies.find((therapy) => therapy.therapist?.id === id), [id, therapies])

  const { data, error, loading } = useQuery<TherapistSpecializationsQuery, TherapistSpecializationsQueryVariables>(
    QUERY,
    {
      variables: {
        therapyId: therapy?.id ?? null,
      },
      fetchPolicy: 'cache-first',
    },
  )

  const specializations = data?.therapistSpecializations || []

  return {
    error: !!error,
    loading,
    specializations: specializations.map(({ id, name, nameEn }) => ({
      id,
      name: language === 'it' ? name : (nameEn ?? name),
    })),
  }
}
