import loadable from '@loadable/component'
import { AssertAuthenticatedRedirect } from '~/domains/auth/components/AssertAuthenticatedRedirect'
import { AssertPatient } from '~/domains/auth/components/AssertPatient'
import { AssertFeatureFlagVariantRedirect } from '~/domains/featureFlags/components/AssertFeatureFlagVariantRedirect'
import { PageNoop } from '~/routes/PageNoop'
import { type Page } from '~/routes/types'
import { PathsGuard } from './Guard'
import { PathsProvider } from './Provider'

export type PathsParams = {
  therapyId?: string
}

const LazyPageView = loadable(() => import('./View'), {
  resolveComponent: (components) => components.View,
})

export const PathsPage: Page = {
  exact: true,
  id: 'paths',
  paths: ['/paths', '/paths/:therapyId'],
  options: {
    isVisibleAppBar: true,
    isVisibleBackButton: false,
    isVisibleNotificationsInbox: true,
    isVisibleTabNavigation: true,
    seoKey: 'paths',
    title: null,
  },
  PageGuard: ({ children }) => (
    <AssertAuthenticatedRedirect>
      <AssertFeatureFlagVariantRedirect name="ff_new_therapies_screen">
        <AssertPatient target="/">
          <PathsGuard>{children}</PathsGuard>
        </AssertPatient>
      </AssertFeatureFlagVariantRedirect>
    </AssertAuthenticatedRedirect>
  ),
  PageLoaded: PageNoop,
  PageProvider: ({ children }) => <PathsProvider>{children}</PathsProvider>,
  PageView: () => <LazyPageView />,
}
