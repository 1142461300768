import { Redirect } from 'react-router-dom'
import { DrawerProvider } from 'ui'
import { CenteredLoader } from '~/components/CenteredLoader'
import { PageRoute } from '~/components/PageRoute'
import { Head } from '~/domains/reservation/components/Head'
import { useReservationFlow } from '~/domains/reservation/hooks/useReservationFlow'
import { useSuggestedTherapists } from '~/hooks/useSuggestedTherapists'
import { TherapistProvider } from '~/hooks/useTherapist'
import { PageScrollEffect } from '~/routes/PageScrollEffect'
import { RecalculateAvailableTherapistsModal } from '../../components/RecalculateAvailableTherapistsModal'
import { RecalculateAvailableTherapistsTrigger } from '../../components/RecalculateAvailableTherapistsTrigger'
import { getReservationRoutes } from '../../utils/getReservationRoutes'
import { BookBySuggestedTherapists } from './BookBySuggestedTherapists'

export const BookBySuggestedTherapist = () => {
  const { selectedTherapist } = useReservationFlow()
  const { loading: suggestedTherapistsLoading, data: suggestedTherapists } = useSuggestedTherapists()

  if (!selectedTherapist) {
    return <Redirect to={getReservationRoutes('/no-matched-therapists')} />
  }

  if (suggestedTherapistsLoading) {
    return <CenteredLoader />
  }

  if (!suggestedTherapists) {
    // TODO if suggestedTherapistsLoading is false and suggestedTherapists is null, show an error message or something
    return null
  }

  return (
    <>
      <PageScrollEffect />

      <PageRoute id="reservation.booking">
        <Head translationId="reservation.booking" />
        <TherapistProvider therapist={selectedTherapist}>
          <DrawerProvider>
            <BookBySuggestedTherapists />
          </DrawerProvider>

          {suggestedTherapists.suggestedTherapists.isChanged && <RecalculateAvailableTherapistsTrigger />}
          <RecalculateAvailableTherapistsModal />
        </TherapistProvider>
      </PageRoute>
    </>
  )
}
