import { type IFlagsmith, type ITraits } from 'flagsmith/types'
import { useCallback, useState } from 'react'
import { config } from '~/clients/flagsmith'
import { useIsNativeApp } from '~/domains/appNative/hooks/useIsNativeApp'
import { useSessionStorageParams } from '~/hooks/useSessionStorageParams'
import { upsertAnonymousFingerprint } from '~/utils/upsertAnonymousFingerprint'

export const useFeatureFlagsInit = (client: IFlagsmith) => {
  const [initialized, setInitialized] = useState(false)

  const appNative = useIsNativeApp()
  const fingerprintId = upsertAnonymousFingerprint()
  const sessionStorage = useSessionStorageParams()

  const init = useCallback(
    async (identity: string, traits: ITraits<string>) => {
      if (initialized) {
        return
      }

      try {
        await client.init({
          ...config,
          identity,
          traits: {
            ...sessionStorage,
            ...traits,
            appNative,
            fingerprintId,
          },
        })
        // eslint-disable-next-line no-empty
      } catch (error) {
      } finally {
        setInitialized(true)
      }
    },
    [appNative, client, fingerprintId, initialized, sessionStorage],
  )

  return {
    init,
    initialized,
  }
}
