import loadable from '@loadable/component'
import { Suspense } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import { CenteredLoader } from '~/components/CenteredLoader'
import { ErrorBoundary } from '~/components/ErrorBoundary'
import { AssertAuthenticatedRedirect } from '~/domains/auth/components/AssertAuthenticatedRedirect'
import { AssertPatientOrTherapist } from '~/domains/auth/components/AssertPatientOrTherapist'
import { useUserActingAsGroup } from '~/domains/auth/hooks/useUserActingAsGroup'
import { TherapistTherapiesProvider } from '~/domains/therapies/TherapistTherapiesProvider'
import { TherapySessionVideocallProvider } from '~/domains/therapy-session/videocall/VideocallProvider'
import { VideocallSidePanelProvider } from '~/domains/therapy-session/videocall/VideocallSidePanel/useVideocallSidePanel'
import { PageNoop } from '~/routes/PageNoop'
import { type Page } from '~/routes/types'
import { getRoute } from '~/utils/getRoute'
import { TherapySessionLivePageGuard } from './Guard'

type Param = {
  therapySessionId: string
}

const LazyTherapySessionLivePageView = loadable(() => import('./View'), {
  resolveComponent: (components) => components.TherapySessionLivePageView,
})

export const TherapySessionLivePage: Page = {
  exact: true,
  id: 'therapy-sessions.live',
  paths: ['/therapy-session/:therapySessionId/live'],
  options: {
    isVisibleAppBar: false,
    isVisibleBackButton: false,
    isVisibleNotificationsInbox: false,
    isVisibleTabNavigation: false,
    seoKey: 'therapy-session.live',
    title: null,
  },
  PageGuard: ({ children }) => {
    const { therapySessionId } = useParams<Param>()

    return (
      <AssertAuthenticatedRedirect to={getRoute(`/therapy-session/${therapySessionId}/live/external`)}>
        <ErrorBoundary fallback={<Redirect to={getRoute('/')} />}>
          <Suspense fallback={<CenteredLoader />}>
            <TherapySessionLivePageGuard>
              <AssertPatientOrTherapist>{children}</AssertPatientOrTherapist>
            </TherapySessionLivePageGuard>
          </Suspense>
        </ErrorBoundary>
      </AssertAuthenticatedRedirect>
    )
  },
  PageLoaded: PageNoop,
  PageProvider: ({ children }) => {
    const group = useUserActingAsGroup()

    if (group === 'therapist') {
      return (
        <TherapistTherapiesProvider>
          <TherapySessionVideocallProvider>
            <VideocallSidePanelProvider>{children}</VideocallSidePanelProvider>
          </TherapySessionVideocallProvider>
        </TherapistTherapiesProvider>
      )
    }

    return (
      <TherapySessionVideocallProvider>
        <VideocallSidePanelProvider>{children}</VideocallSidePanelProvider>
      </TherapySessionVideocallProvider>
    )
  },
  PageView: () => <LazyTherapySessionLivePageView />,
}
