import { createContext, type ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react'

type Context = {
  current: number
  goNext: () => void
  goPrev: () => void
  goTo: (index: number) => void
  length: number
  nextDisabled: boolean
  prevDisabled: boolean
  updateRightEdgeReached: (value: boolean) => void
}

const CarouselContext = createContext<Context | null>(null)

type Props = {
  children: ReactNode
  length: number
  onSetCurrent?: (index: number) => void
}

export const CarouselProvider = ({ children, length, onSetCurrent }: Props) => {
  const [current, setCurrent] = useState(0)
  const [isRightEdgeReached, setIsRightEdgeReached] = useState(false)

  const nextDisabled = useMemo(
    () => isRightEdgeReached || current === length - 1,
    [current, isRightEdgeReached, length],
  )

  const prevDisabled = useMemo(() => current === 0, [current])

  const goNext = useCallback(() => {
    if (nextDisabled) {
      return
    }

    setCurrent(current + 1)
    onSetCurrent?.(current + 1)
  }, [current, nextDisabled, onSetCurrent])

  const goPrev = useCallback(() => {
    if (prevDisabled) {
      return
    }

    setCurrent(current - 1)
    onSetCurrent?.(current - 1)
  }, [current, onSetCurrent, prevDisabled])

  const goTo = useCallback(
    (index: number) => {
      if (index < 0) {
        onSetCurrent?.(0)

        return setCurrent(0)
      }

      if (index >= length) {
        onSetCurrent?.(length - 1)

        return setCurrent(length - 1)
      }

      onSetCurrent?.(index)
      setCurrent(index)
    },
    [length, onSetCurrent],
  )

  const updateRightEdgeReached = useCallback((value: boolean) => setIsRightEdgeReached(value), [])

  useEffect(() => {
    if (current > 0 && current >= length) {
      onSetCurrent?.(length - 1)
      setCurrent(length - 1)
    }
  }, [current, length, onSetCurrent])

  return (
    <CarouselContext.Provider
      value={{ current, goNext, goPrev, goTo, length, nextDisabled, prevDisabled, updateRightEdgeReached }}
    >
      {children}
    </CarouselContext.Provider>
  )
}

export const useCarousel = () => {
  const state = useContext(CarouselContext)

  if (!state) {
    throw new Error('The `useCarousel` should be wrapped with `CarouselProvider`.')
  }

  return state
}
