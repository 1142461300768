import loadable from '@loadable/component'
import { Flex, MaxWidth400px, OverflowAuto, PageLayout } from 'cdk'
import { useBreakpoints } from 'hooks'
import { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { Button, Card, Text, type TextProps } from 'ui'
import { BoxShadowBottom } from '~/components/BoxShadowBottom'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'

type Props = {
  score: number
}

const Fallback = styled.div`
  width: 110%;
  height: 210px;
`

const LazyTrustpilotImage = loadable(() => import('./TrustpilotImage.lazy'), {
  resolveComponent: (components) => components.TrustpilotImage,
  fallback: <Fallback />,
})

export const PositiveReview = ({ score }: Props) => {
  const onClickGmail = useCallback(() => {
    window.open('https://mail.google.com', '_blank')
  }, [])

  const onClickOutlook = useCallback(() => {
    window.open('https://outlook.live.com/mail/0/inbox', '_blank')
  }, [])

  const { isSmallOnly } = useBreakpoints()

  const textAlign = useMemo((): Required<TextProps['textAlign']> => {
    if (isSmallOnly) {
      return 'left'
    }

    return 'center'
  }, [isSmallOnly])

  if (score > 4) {
    return (
      <>
        <PageLayout $grow={1} $shrink={1} maxWidth="640px">
          <OverflowAuto>
            <Flex $pb={24}>
              <TranslationMarkdown id="productReviews.title.super" kind="h1" textAlign={textAlign} />
            </Flex>

            <Flex $pb={16}>
              <TranslationMarkdown
                fontWeight="400"
                id="productReviews.subtitle.super"
                kind="h3"
                textAlign={textAlign}
              />
            </Flex>

            <Flex $mdAlign="center" $pb={16}>
              <LazyTrustpilotImage />
            </Flex>

            <Text fontWeight="400" kind="h3" textAlign={textAlign}>
              <Translation id="productReviews.note.super" />
            </Text>
          </OverflowAuto>
        </PageLayout>
        <BoxShadowBottom>
          <MaxWidth400px $align="center" $direction="row" $justify="space-between">
            <Button kind="primary" onClick={onClickGmail}>
              <Translation id="productReviews.action.super.gmail" />
            </Button>
            <Flex $px={16} />
            <Button kind="primary" onClick={onClickOutlook}>
              <Translation id="productReviews.action.super.outlook" />
            </Button>
          </MaxWidth400px>
        </BoxShadowBottom>
      </>
    )
  }

  return (
    <PageLayout maxWidth="400px">
      <Card>
        <Flex>
          <Flex $align="center" $pb={8}>
            <Text kind="h1">😊</Text>
          </Flex>

          <Flex $pb={24}>
            <Text kind="h2" textAlign="center">
              <Translation id="productReviews.title.positive" />
            </Text>
          </Flex>

          <Flex $pb={32}>
            <Text kind="h3" textAlign="center">
              <Translation id="productReviews.subtitle.positive" />
            </Text>
          </Flex>

          <Flex $pb={32}>
            <TranslationMarkdown id="productReviews.text.neutral" kind="paragraph" pt={8} textAlign="center" />
          </Flex>
        </Flex>
      </Card>
    </PageLayout>
  )
}
