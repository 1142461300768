import { Flex, Form, PageLayout } from 'cdk'
import { useCallback, useState } from 'react'
import { Button, Card, Text } from 'ui'
import { Translation } from '~/components/Translation'
import { ReactHookFormProvider, ReactHookFormTextArea, useReactHookForm } from '~/domains/react-hook-form'
import { useFormTranslations } from '~/hooks/useFormTranslations'
import { useToasts } from '~/hooks/useToasts'
import { useTranslation } from '~/i18n/hooks/useTranslation'
import { useUpdateProductReviewBody } from '../hooks/useUpdateProductReviewBody'
import { ReviewThankYou } from './ReviewThankYou'

type UpdateProductReviewForm = {
  body: string
}

const max = 600

type Props = {
  id: string
}

export const NegativeReview = ({ id }: Props) => {
  const [reviewLeft, setReviewLeft] = useState(false)

  const [updateProductReviewBody] = useUpdateProductReviewBody()

  const { addToast } = useToasts()

  const form = useReactHookForm<UpdateProductReviewForm>({ defaultValues: { body: '' } })

  const {
    errors: { required },
    placeholders,
  } = useFormTranslations()

  const draft = form.watch('body')

  const helperText = useTranslation('forms.helperTexts.textareaCounter', {
    current: draft.length || '0',
    max,
  })

  const onSubmit = useCallback(
    async ({ body }: UpdateProductReviewForm) => {
      try {
        await updateProductReviewBody({ variables: { input: { id, body } } })

        setReviewLeft(true)
      } catch (error) {
        addToast({ translationId: 'generic.errorOccurred.title', type: 'alert' })
      }
    },
    [addToast, id, updateProductReviewBody],
  )

  if (reviewLeft) {
    return <ReviewThankYou />
  }

  return (
    <PageLayout maxWidth="400px">
      <Card>
        <ReactHookFormProvider {...form}>
          <Form onSubmit={form.handleSubmit(onSubmit)}>
            <Flex $align="center" $pb={8}>
              <Text kind="h1">😔</Text>
            </Flex>

            <Flex $pb={8}>
              <Text kind="h2" textAlign="center">
                <Translation id="productReviews.title.negative" />
              </Text>
            </Flex>

            <Flex $pb={32}>
              <Text kind="paragraph" textAlign="center">
                <Translation id="productReviews.subtitle.negative" />
              </Text>
            </Flex>

            <Flex $pt={8}>
              <ReactHookFormTextArea
                helperText={helperText}
                max={max}
                minHeight="160px"
                name="body"
                placeholder={placeholders.productReviewBody}
                rules={{ max, required }}
              />
            </Flex>

            <Flex $pt={24}>
              <Button isLoading={form.formState.isSubmitting} kind="primary" type="submit">
                <Translation id="actions.send" />
              </Button>
            </Flex>
          </Form>
        </ReactHookFormProvider>
      </Card>
    </PageLayout>
  )
}
