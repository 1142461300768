import { Pressable } from 'cdk'
import { Modal, ModalProvider, ModalTrigger } from 'ui'
import { TherapyPathCard } from '~/components/TherapyPathCard'
import { ChangeTherapistModal } from '~/domains/changeTherapist/components/ChangeTherapistModal'
import { useModals } from '~/domains/modals'
import { usePatientTherapiesQuery } from '~/domains/patient/hooks/usePatientTherapiesQuery'
import { type TherapyTherapyPathType } from '~/types/graphql'
import { StartTherapyPathModalContent } from './StartTherapyPathModalContent'
import { TherapyAlreadyOngoingModalContent } from './TherapyAlreadyOngoingModalContent'

type Props = {
  therapyPathType: TherapyTherapyPathType
}

export const StartTherapyPathCard = ({ therapyPathType }: Props) => {
  const { therapies, loading, error } = usePatientTherapiesQuery()
  const { open } = useModals()

  const isNutritionTherapyPath = ['PATH_NUTRITION_DCA', 'PATH_NUTRITION_WEIGHT_LOSS'].includes(therapyPathType)

  const ongoingTherapy = therapies
    .filter((therapy) => therapy.ongoing)
    .find(({ therapyPath }) =>
      isNutritionTherapyPath
        ? therapyPath.type === 'PATH_NUTRITION_WEIGHT_LOSS' || therapyPath.type === 'PATH_NUTRITION_DCA'
        : therapyPath.type === therapyPathType,
    )

  const onChangeTherapist = () => {
    if (!ongoingTherapy || therapyPathType === 'MYSELF_PSYCHIATRY') {
      return
    }

    open(`changeTherapist:${ongoingTherapy.id}`)
  }

  return (
    <>
      <ModalProvider>
        <ModalTrigger data-test-id={`cta-start-therapy-path-${therapyPathType.toLowerCase()}`}>
          <Pressable disabled={loading || error}>
            <TherapyPathCard therapyPathType={therapyPathType} />
          </Pressable>
        </ModalTrigger>
        <Modal>
          {ongoingTherapy ? (
            <TherapyAlreadyOngoingModalContent
              onChangeTherapist={onChangeTherapist}
              therapistFullName={ongoingTherapy?.therapist?.fullName}
              therapistId={ongoingTherapy?.therapist?.id}
              therapyPathType={ongoingTherapy?.therapyPath?.type}
            />
          ) : (
            <StartTherapyPathModalContent therapyPathType={therapyPathType} />
          )}
        </Modal>
      </ModalProvider>

      {!!ongoingTherapy?.id && <ChangeTherapistModal therapyId={ongoingTherapy.id} />}
    </>
  )
}
