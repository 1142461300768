import { Flex, Pressable } from 'cdk'
import { Icon } from 'icons'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Card, Text } from 'ui'
import { Translation } from '~/components/Translation'
import { getRoute } from '~/utils/getRoute'

export const MemberGetMemberSuggestion = () => {
  const history = useHistory()

  const onClick = useCallback(() => {
    history.push(getRoute('/settings/payments'))
  }, [history])

  return (
    <Flex $pt={16}>
      <Card $mdAlign="center" $mdDirection="row">
        <Flex $align="flex-start" $direction="row" $grow={1} $justify="center" $mdAlign="center" $shrink={1}>
          <Flex $pr={8}>
            <Icon colorName="neutral-80" name="badge-check" size={32} />
          </Flex>

          <Flex $grow={1} $shrink={1}>
            <Text fontWeight="600" kind="paragraph">
              <Translation id="home.patient.memberGetMember.suggestion.title" />
            </Text>

            <Text kind="paragraph">
              <Translation id="home.patient.memberGetMember.suggestion.description" />
            </Text>

            <Flex $lgHide>
              <Pressable onClick={onClick}>
                <Flex $align="flex-start">
                  <Text colorName="primary" fontWeight="600" kind="paragraph" textDecoration="underline">
                    <Translation id="home.patient.memberGetMember.suggestion.action" />
                  </Text>
                </Flex>
              </Pressable>
            </Flex>
          </Flex>
        </Flex>

        <Flex $hide $lgDirection="row" $lgHide={false}>
          <Flex $lgGrow={1} $lgShrink={1} $pt={4}>
            <Text colorName="darker" fontWeight="600" kind="paragraph">
              <Pressable onClick={onClick}>
                <Text colorName="primary" fontWeight="600" kind="paragraph" textDecoration="underline">
                  <Translation id="home.patient.memberGetMember.suggestion.action" />
                </Text>
              </Pressable>
            </Text>
          </Flex>
        </Flex>
      </Card>
    </Flex>
  )
}
