import { COLOR_PRIMARY, COLOR_PRIMARY_40 } from 'design-tokens'
import ContentLoader from 'react-content-loader'
import { Card } from 'ui'

export const TherapySessionCardSkeleton = () => (
  <Card>
    <ContentLoader backgroundColor={COLOR_PRIMARY_40} foregroundColor={COLOR_PRIMARY} speed={1.5} viewBox="0 0 400 270">
      <circle cx="20" cy="20" r="20" />
      <rect height="6" rx="3" ry="3" width="88" x="48" y="8" />
      <rect height="6" rx="3" ry="3" width="52" x="48" y="26" />
      <rect height="6" rx="3" ry="3" width="410" x="0" y="56" />
      <rect height="6" rx="3" ry="3" width="380" x="0" y="72" />
      <rect height="6" rx="3" ry="3" width="178" x="0" y="88" />
      <rect height="6" rx="3" ry="3" width="410" x="0" y="114" />
      <rect height="6" rx="3" ry="3" width="410" x="0" y="130" />
      <rect height="6" rx="3" ry="3" width="410" x="0" y="114" />
      <rect height="6" rx="3" ry="3" width="380" x="0" y="178" />
      <rect height="6" rx="3" ry="3" width="52" x="0" y="210" />
      <rect height="6" rx="3" ry="3" width="88" x="0" y="226" />
      <rect height="6" rx="3" ry="3" width="380" x="0" y="242" />
      <rect height="6" rx="3" ry="3" width="178" x="0" y="258" />
    </ContentLoader>
  </Card>
)
