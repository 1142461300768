import { Flex, Pressable } from 'cdk'
import { BORDER_RADIUS_XS, COLOR_NEUTRAL_20, OPACITY_0, OPACITY_100, SPACING_MD, SPACING_SM } from 'design-tokens'
import { Icon } from 'icons'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { Avatar, Text } from 'ui'
import { useCurrentUser } from '~/hooks/useCurrentUser'
import { FlexWithTransition } from './common/FlexWithTransition'

const IconPressable = styled(Pressable)`
  &:hover {
    border-radius: ${BORDER_RADIUS_XS};
    background-color: ${COLOR_NEUTRAL_20};
  }
`

type IconTriggerContainerProps = {
  $showIcon?: boolean
}

const IconTriggerContainer = styled(FlexWithTransition)<IconTriggerContainerProps>`
  ${({ $showIcon = false }) => css`
    visibility: ${$showIcon ? 'visible' : 'hidden'};
  `};
`

type SideBarFooterProps = {
  extended: boolean
  onIconClick?: () => void
  onAvatarClick?: () => void
  showIcon?: boolean
}

const IconTrigger = ({ extended, onIconClick }: SideBarFooterProps) => (
  <Flex $align={extended ? 'flex-end' : 'center'} $justify="center">
    <IconPressable onClick={onIconClick}>
      <Flex $align="center">
        <Icon colorName="neutral-80" name={extended ? 'srns-sidebar-collapse' : 'srns-sidebar-expand'} size={24} />
      </Flex>
    </IconPressable>
  </Flex>
)

export const SideBarFooter = ({ extended, showIcon, onIconClick, onAvatarClick }: SideBarFooterProps) => {
  const user = useCurrentUser()

  return (
    <Flex $direction="column" $gap={SPACING_MD}>
      <IconTriggerContainer $opacity={showIcon ? OPACITY_100 : OPACITY_0} $showIcon={showIcon}>
        <IconTrigger extended={extended} onIconClick={onIconClick} />
      </IconTriggerContainer>
      <Flex $direction="row" $gap={SPACING_SM}>
        <Link onClick={onAvatarClick} to="/settings/account">
          <Avatar name={user.fullName} size="sm" />
        </Link>
        <FlexWithTransition $justify="center" $opacity={extended ? OPACITY_100 : OPACITY_0} $shrink={1}>
          <Text ellipsis kind="paragraph">
            {user.fullName}
          </Text>
          <Text colorName="neutral-80" ellipsis kind="footnote">
            {user.email}
          </Text>
        </FlexWithTransition>
      </Flex>
    </Flex>
  )
}
