import { Flex, PageLayout } from 'cdk'
import { Card, Text } from 'ui'
import { Translation } from '~/components/Translation'

export const ReviewThankYou = () => (
  <PageLayout maxWidth="400px">
    <Card>
      <Flex>
        <Flex $align="center" $pb={8}>
          <Text kind="h1">😊</Text>
        </Flex>

        <Flex $pb={24}>
          <Text kind="h2" textAlign="center">
            <Translation id="productReviews.title.negativeReviewLeft" />
          </Text>
        </Flex>

        <Flex $pb={24}>
          <Text kind="paragraph" textAlign="center">
            <Translation id="productReviews.subtitle.negativeReviewLeft" />
          </Text>
        </Flex>
      </Flex>
    </Card>
  </PageLayout>
)
