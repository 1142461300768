import { Flex } from 'cdk'
import { SPACING_SM } from 'design-tokens'
import { Text } from 'ui'
import { Translation } from '~/components/Translation'

type Props = {
  about: string
  sex: number
}

export const TherapistOverviewCardDetailContentSelfDescription = ({ about, sex }: Props) => (
  <Flex $gap={SPACING_SM}>
    <Text kind="h3">
      <Translation
        id={
          sex === 1
            ? 'therapist.overviewCard.selfDescription.title.male'
            : 'therapist.overviewCard.selfDescription.title.female'
        }
      />
    </Text>
    <Text>{about}</Text>
  </Flex>
)
