import { type PropsWithChildren } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import { usePatientTherapiesQuery } from '~/domains/patient/hooks/usePatientTherapiesQuery'
import { getRoute } from '~/utils/getRoute'
import { type PathsParams } from '.'

type PathsGuardProps = PropsWithChildren

export const PathsGuard = ({ children }: PathsGuardProps) => {
  const { therapyId } = useParams<PathsParams>()
  const { therapies } = usePatientTherapiesQuery()

  const ongoingTherapies = therapies.filter((therapy) => therapy.ongoing)

  if (therapyId == null && ongoingTherapies.length > 0) {
    return <Redirect to={getRoute(`/paths/${ongoingTherapies[0].id}`)} />
  }

  return children
}
