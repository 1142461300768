import { Flex } from 'cdk'
import {
  COLOR_PRIMARY_40,
  COLOR_PRIMARY_50,
  COLOR_PRIMARY_80,
  ELEVATION_SM,
  SPACING_MD,
  SPACING_XS,
} from 'design-tokens'
import styled, { css } from 'styled-components'
import { Card, Text } from 'ui'
import { StartTherapyPathIcon } from '~/domains/agenda/components/StartTherapyPath/components/StartTherapyPathIcon'
import { StartTherapyPathName } from '~/domains/agenda/components/StartTherapyPath/components/StartTherapyPathName'
import { isFirstFreeSessionTherapyPathType } from '~/domains/therapies/utils'
import { type TranslationId } from '~/i18n/types'
import { type TherapyTherapyPathType } from '~/types/graphql'
import { ChipFirstTherapySessionFree } from './ChipFirstTherapySessionFree'
import { Translation } from './Translation'

type TherapyPathProps = {
  $active?: boolean
  therapyPathType: TherapyTherapyPathType
}

const therapyPathTranslations: Record<
  TherapyTherapyPathType,
  Extract<TranslationId, `therapies.startTherapyPath.card.description.${string}`>
> = {
  COUPLES_PSYCHOTHERAPY: 'therapies.startTherapyPath.card.description.couples',
  MYSELF_PSYCHIATRY: 'therapies.startTherapyPath.card.description.psychiatry',
  MYSELF_PSYCHOTHERAPY: 'therapies.startTherapyPath.card.description.individual',
  PATH_ASSERTIVE_COMMUNICATION: 'therapies.startTherapyPath.card.description.individual',
  PATH_COACHING: 'therapies.startTherapyPath.card.description.coaching',
  PATH_NUTRITION_DCA: 'therapies.startTherapyPath.card.description.nutrition.dca',
  PATH_NUTRITION_WEIGHT_LOSS: 'therapies.startTherapyPath.card.description.nutrition.weightLoss',
  PATH_SEXOLOGY: 'therapies.startTherapyPath.card.description.sexology',
  PATH_SLEEP: 'therapies.startTherapyPath.card.description.individual',
  UNDERAGE_PSYCHOTHERAPY: 'therapies.startTherapyPath.card.description.individual',
}

const TherapyPathContainerVariant = styled(Card)<Pick<TherapyPathProps, '$active'>>`
  ${({ $active }) => css`
    box-shadow: ${$active && ELEVATION_SM};
    outline-color: ${$active && COLOR_PRIMARY_50};

    &:hover {
      box-shadow: ${ELEVATION_SM};
      outline-color: ${$active ? COLOR_PRIMARY_80 : COLOR_PRIMARY_40};
    }
  `}
`

export const TherapyPathCard = ({ $active = false, therapyPathType }: TherapyPathProps) => (
  <TherapyPathContainerVariant $active={$active}>
    <Flex $direction="column" $gap={SPACING_XS}>
      <Flex $direction="row" $gap={SPACING_MD} $justify="space-between">
        <StartTherapyPathIcon size={48} therapyPathType={therapyPathType} />
        {isFirstFreeSessionTherapyPathType(therapyPathType) && (
          <Flex>
            <ChipFirstTherapySessionFree />
          </Flex>
        )}
      </Flex>
      <Flex>
        <StartTherapyPathName therapyPathType={therapyPathType} />
        <Text kind="paragraph">
          <Translation id={therapyPathTranslations[therapyPathType]} />
        </Text>
      </Flex>
    </Flex>
  </TherapyPathContainerVariant>
)
