import { Flex } from 'cdk'
import { COLOR_RED_80, SPACING_XS } from 'design-tokens'
import { useFormContext } from 'react-hook-form'
import styled, { css } from 'styled-components'
import { Checkbox } from 'ui'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { usePartnership } from '~/hooks/usePartnership'
import { type CreatePatientInput } from '~/types/graphql'

type StyledLinkFlexProps = {
  $hasError: boolean
}

const StyledLinkFlex = styled(Flex)<StyledLinkFlexProps>`
  a {
    ${({ $hasError }) =>
      $hasError &&
      css`
        color: currentcolor;

        &:hover,
        &:focus {
          color: ${COLOR_RED_80};
        }
      `}
  }
`

export const FieldTermsAndConditions = () => {
  const { formState, register, watch } = useFormContext<CreatePatientInput>()
  const termsAndConditions = watch('termsAndConditions')
  const partnership = usePartnership()
  const hasError = formState.touchedFields.termsAndConditions === true && !termsAndConditions

  return (
    <StyledLinkFlex $align="center" $direction="row" $gap={SPACING_XS} $hasError={hasError}>
      <Checkbox id="termsAndConditions" {...register('termsAndConditions', { required: true })} required />
      <Flex $grow={1} $shrink={1} as="label" htmlFor="termsAndConditions">
        <TranslationMarkdown
          colorName={hasError ? 'error' : 'darker'}
          colorNameBold={hasError ? 'error' : 'primary'}
          id={
            partnership === 'MY_SECRET_CASE' ? 'forms.labels.termsAndConditionsMysc' : 'forms.labels.termsAndConditions'
          }
          kind="caption"
          target="_blank"
        />
      </Flex>
    </StyledLinkFlex>
  )
}
