import { Flex, MountOn, Pressable } from 'cdk'
import { useCopyToClipboard } from 'hooks'
import { useCallback, useMemo } from 'react'
import { matchPath, useLocation } from 'react-router-dom'
import { Card, Divider } from 'ui'
import { type TherapistAgendaTherapySession } from '~/domains/agenda/types'
import { useTrackEvent } from '~/domains/analytics/hooks/useTrackEvent'
import { GenericErrorContent } from '~/domains/error/GenericError'
import { useTherapistTherapies } from '~/domains/therapies/TherapistTherapiesProvider'
import { getPatientDetailRoute } from '~/domains/therapy-session/videocall/VideocallSidePanel/contents/PatientDetail/getPatientDetailRoute'
import { useCurrentUser } from '~/hooks/useCurrentUser'
import { useToasts } from '~/hooks/useToasts'
import { type TherapySessionTypeCost } from '~/types/graphql'
import { getGoogleMeetUrlWithEmail } from '~/utils/getGoogleMeetUrlWithEmail'
import { getNewGoogleMeetUrlWithEmail } from '~/utils/getNewGoogleMeetUrlWithEmail'
import { getRoute } from '~/utils/getRoute'
import { CenteredLoader } from '../CenteredLoader'
import { TranslationMarkdown } from '../TranslationMarkdown'
import { DropdownMenuTherapist } from './components/DropdownMenuTherapist'
import { TherapySessionActions } from './components/TherapySessionActions'
import { TherapySessionCardPatientInfo } from './components/TherapySessionCardPatientInfo'

type Props = Pick<
  TherapistAgendaTherapySession,
  | 'bonus'
  | 'churn'
  | 'endAt'
  | 'free'
  | 'id'
  | 'notes'
  | 'patient'
  | 'provider'
  | 'recurrency'
  | 'repeatEveryWeek'
  | 'startAt'
  | 'status'
  | 'therapist'
  | 'therapyPathType'
  | 'url'
> & { isInTherapySessionLive?: boolean; typeCost: TherapySessionTypeCost | null }

export const TherapySessionCardTherapist = ({
  bonus,
  churn,
  endAt,
  free,
  id,
  isInTherapySessionLive = false,
  patient,
  provider,
  recurrency,
  repeatEveryWeek,
  startAt,
  status,
  therapist,
  therapyPathType,
  typeCost,
  url,
}: Props) => {
  const { pathname } = useLocation()
  const [, copy] = useCopyToClipboard()
  const { addToast } = useToasts()
  const { loading, therapies } = useTherapistTherapies()

  const matchChatPath = !!matchPath(pathname, { path: [getRoute('/chat')] })
  const matchPatientDetailPath = !!matchPath(pathname, { path: [getPatientDetailRoute('/detail')] })
  const matchPatientPath = !!matchPath(pathname, { path: [getRoute('/patients')] })

  const therapy = useMemo(
    () => therapies.find((therapy) => therapy.patient?.id === patient.id),
    [patient.id, therapies],
  )

  const { email } = useCurrentUser()
  const trackClick = useTrackEvent('CLICK')

  const onClickHelp = useCallback(() => {
    trackClick({ name: 'clicks.support.open-new-google-meet' })

    window.open(getNewGoogleMeetUrlWithEmail(email), '_blank')
  }, [email, trackClick])

  const onClickCopy = useCallback(async () => {
    trackClick({ name: 'clicks.support.copy-google-meet-link-in-clipboard' })

    if (!url) {
      return null
    }

    const result = await copy(getGoogleMeetUrlWithEmail(url, email))

    addToast(
      result
        ? { translationId: 'therapySession.generic.helpWithGoogleMeet.success', type: 'success' }
        : { translationId: 'generic.error', type: 'alert' },
    )
  }, [addToast, copy, email, trackClick, url])

  if (loading) {
    return <CenteredLoader />
  }

  if (!therapy) {
    return <GenericErrorContent />
  }

  return (
    <Card $p={0}>
      <Flex $align="center" $direction="row" $justify="space-between" $pb={16} $pt={24} $px={16}>
        <Flex
          $grow={1}
          $lgAlign={matchChatPath || matchPatientDetailPath ? 'stretch' : 'center'}
          $lgDirection={matchChatPath || matchPatientDetailPath ? 'column' : 'row'}
          $lgJustify={matchChatPath || matchPatientDetailPath ? 'flex-start' : 'space-between'}
          $shrink={1}
        >
          <Flex $align="center" $direction="row" $grow={1} $justify="space-between" $shrink={1}>
            <TherapySessionCardPatientInfo
              bonus={bonus}
              endAt={endAt}
              id={id}
              isInTherapySessionLive={isInTherapySessionLive}
              patient={patient}
              repeatEveryWeek={repeatEveryWeek}
              startAt={startAt}
              status={status}
              therapyPathType={therapyPathType}
              therapySessionTypeCost={typeCost}
            />
            <MountOn mediaQuery="lt-lg">
              <Flex $align="center" $justify="center" $pl={24}>
                <DropdownMenuTherapist
                  endAt={endAt}
                  free={free}
                  id={id}
                  patient={patient}
                  recurrency={recurrency}
                  startAt={startAt}
                  status={status}
                  therapyPathType={therapyPathType}
                  typeCost={typeCost}
                />
              </Flex>
            </MountOn>
          </Flex>
          <Flex
            $lgBasis={matchChatPath || matchPatientDetailPath ? 'auto' : '180px'}
            $lgPl={matchPatientPath || matchChatPath || matchPatientDetailPath ? 0 : 16}
          >
            <TherapySessionActions
              churn={churn}
              id={id}
              isInTherapySessionLive={isInTherapySessionLive}
              patient={patient}
              provider={provider}
              recurrency={recurrency}
              startAt={startAt}
              status={status}
              therapist={therapist}
              therapyId={therapy.id}
              url={url}
            />
          </Flex>
        </Flex>
        <MountOn mediaQuery="gt-md">
          <Flex $align="center" $justify="center" $pl={24}>
            <DropdownMenuTherapist
              endAt={endAt}
              free={free}
              id={id}
              patient={patient}
              recurrency={recurrency}
              startAt={startAt}
              status={status}
              therapyPathType={therapyPathType}
              typeCost={typeCost}
            />
          </Flex>
        </MountOn>
      </Flex>

      <Flex>
        <Divider colorName="primary" />
        <Flex $p={16}>
          <Flex $align="flex-start">
            {provider === 'HOUSE' ? (
              <Pressable onClick={onClickHelp}>
                <TranslationMarkdown
                  colorName="neutral-80"
                  id="therapySession.generic.helpWithInHouseVideocall.therapist"
                  kind="caption"
                  textAlign="left"
                  values={{ email }}
                />
              </Pressable>
            ) : (
              <Pressable onClick={onClickCopy}>
                <TranslationMarkdown
                  colorName="neutral-80"
                  id="therapySession.generic.helpWithGoogleMeet"
                  kind="caption"
                  textAlign="left"
                />
              </Pressable>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Card>
  )
}
