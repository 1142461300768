import { Flex } from 'cdk'
import { SPACING_SM, SPACING_XL } from 'design-tokens'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom-v5-compat'
import { Button, Modal, ModalProvider, Text } from 'ui'
import { Translation } from '~/components/Translation'
import { useTrackEventClick } from '~/domains/analytics/hooks/useTrackEventClick'
import { useTrackEventModal } from '~/domains/analytics/hooks/useTrackEventModal'
import { usePatientTherapies } from '~/domains/patient/hooks/usePatientTherapies'
import { getRoute } from '~/utils/getRoute'
import { useClinicalTestAnswer } from '../hooks/useClinicalTestAnswer'

const ExitConfermationModalTrackEventEffect = () => {
  const trackDismiss = useTrackEventModal('dismiss')
  const trackOpen = useTrackEventModal('open')

  useEffect(() => {
    trackOpen('clinicalTestExitConfirmation')

    return () => trackDismiss('clinicalTestExitConfirmation')
  }, [trackDismiss, trackOpen])

  return null
}

type Props = {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
}

export const ExitConfirmationModal = ({ isOpen, setIsOpen }: Props) => {
  const { clinicalTestAnswerId } = useParams<{ clinicalTestAnswerId: string }>()
  const { clinicalTestAnswer } = useClinicalTestAnswer(clinicalTestAnswerId)
  const { therapies } = usePatientTherapies()
  const trackClick = useTrackEventClick()

  if (!clinicalTestAnswer) {
    return null
  }

  const therapist = therapies.find((therapy) => therapy.id === clinicalTestAnswer.therapyId)?.therapist

  return (
    <ModalProvider isOpen={isOpen} onIsOpenChange={setIsOpen}>
      <Modal>
        <ExitConfermationModalTrackEventEffect />
        <Flex $gap={SPACING_XL}>
          <Flex $gap={SPACING_SM}>
            <Text kind="h3">
              <Translation id="clinicalTests.modal.title" />
            </Text>
            <Text kind="paragraph">
              <Translation id="clinicalTests.modal.body" values={{ therapistFullName: therapist?.fullName ?? '' }} />
            </Text>
          </Flex>
          <Flex $direction="row" $gap={SPACING_SM} $justify="flex-end">
            <Button
              isGhost
              kind="secondary"
              onClick={() => {
                setIsOpen(false)
                trackClick('clinical-test.exit-confirmation-modal.cancel-closing')
              }}
            >
              <Translation id="clinicalTests.modal.cancelCta" />
            </Button>
            <Link to={getRoute(`/chat/${therapist?.id}`)}>
              <Button kind="primary" onClick={() => trackClick('clinical-test.exit-confirmation-modal.return-to-chat')}>
                <Translation id="clinicalTests.modal.confirmCta" />
              </Button>
            </Link>
          </Flex>
        </Flex>
      </Modal>
    </ModalProvider>
  )
}
