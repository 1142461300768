import { useCallback } from 'react'
import { useTrackEvent } from '~/domains/analytics/hooks/useTrackEvent'
import { useCurrentUserNullable } from '~/hooks/useCurrentUser'

export const useVideocallEventLogger = () => {
  const currentUser = useCurrentUserNullable()
  const trackFailure = useTrackEvent('FAILURE')

  const logFailure = useCallback(
    (eventName: string, error: unknown) => {
      trackFailure({
        name: eventName,
        payload: {
          userId: currentUser.id,
          error: String(error),
        },
      })
    },
    [trackFailure, currentUser.id],
  )

  return logFailure
}
