import { Flex, Pressable } from 'cdk'
import { SPACING_0, SPACING_3XS, SPACING_MD } from 'design-tokens'
import { matchPath, useLocation } from 'react-router-dom'
import { Avatar, Text } from 'ui'
import { DateTime } from '~/components/DateTime'
import { RecurrencyTime } from '~/components/RecurrencyTime'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { useFeatureFlagsByUserId } from '~/domains/featureFlags'
import { TherapistOverviewCardDetail } from '~/domains/therapist/TherapistOverviewCard/Detail'
import { getRoute } from '~/utils/getRoute'
import { getTherapyPathTranslationKey } from '~/utils/getTherapyPathTranslationKey'
import { useTherapySession } from '../../../hooks/useTherapySession'
import { EditAndDeleteAppointmentButtons } from './EditAndDeleteAppointmentButtons'

type AppointmentDetailsProps = {
  canManage?: boolean
}

export const AppointmentDetails = ({ canManage = false }: AppointmentDetailsProps) => {
  const { endAt, repeatEveryWeek, startAt, therapist, therapyPathType } = useTherapySession()
  const { pathname } = useLocation()
  const { isControl } = useFeatureFlagsByUserId()

  const therapySessionTypeTranslationId = getTherapyPathTranslationKey(therapyPathType ?? 'MYSELF_PSYCHOTHERAPY')

  const isTherapiesPath = !!matchPath(pathname, { path: [getRoute('/therapies')] })

  return (
    <Flex $direction="row">
      {!isTherapiesPath && (
        <Flex>
          <Avatar image={therapist?.therapist?.profileImage?.s} name={therapist?.fullName} size="sm" />
        </Flex>
      )}
      <Flex $align="flex-start" $pl={isTherapiesPath ? SPACING_0 : SPACING_MD} $shrink={1}>
        <Flex $pb={SPACING_3XS}>
          <TranslationMarkdown colorName="grey-80" id={therapySessionTypeTranslationId} kind="paragraph" />
        </Flex>

        {!isTherapiesPath && (
          <TherapistOverviewCardDetail therapyPath={therapyPathType ?? 'MYSELF_PSYCHOTHERAPY'}>
            <Pressable data-test-id="therapist-profile-link">
              <Text colorName="primary" fontWeight="600" kind="paragraph" textDecoration="underline">
                {therapist?.fullName}
              </Text>
            </Pressable>
          </TherapistOverviewCardDetail>
        )}

        {!!startAt && !!endAt && (
          <Flex $pt={SPACING_3XS}>
            <DateTime endAt={endAt} startAt={startAt} />
          </Flex>
        )}
        {repeatEveryWeek && startAt && (
          <Flex $pt={SPACING_3XS}>
            <RecurrencyTime repeatEveryWeek={repeatEveryWeek} startAt={startAt} />
          </Flex>
        )}
        {isControl('ff_manage_therapy_session') && canManage && (
          <Flex $pt={SPACING_MD}>
            <EditAndDeleteAppointmentButtons />
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}
