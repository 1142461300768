import { FloatingPortal, useMergeRefs } from '@floating-ui/react'
import { forwardRef, type HTMLProps } from 'react'
import styled from 'styled-components'
import { Card } from 'ui'
import { useTooltipContext } from './TooltipContext'

// Note: Modals, Popovers, Tooltips, SelectNew must have the same zIndex.
const TooltipContentContainer = styled(Card)`
  width: fit-content;
  margin: 0;
`

export const TooltipContent = forwardRef<HTMLDivElement, HTMLProps<HTMLDivElement>>(
  ({ children, ...props }, propRef) => {
    const { strategy, y, x, getFloatingProps, isMounted, kind, refs, styles } = useTooltipContext()
    const ref = useMergeRefs([refs.setFloating, propRef])

    if (!isMounted) {
      return null
    }

    return (
      <FloatingPortal>
        <TooltipContentContainer
          ref={ref}
          $maxWidth="324px"
          $zIndex={10}
          {...(kind === 'title' && { $borderRadius: 8, $elevationName: '0', $p: 8 })}
          {...getFloatingProps({
            ...props,
            style: {
              position: strategy,
              top: y ?? 0,
              left: x ?? 16,
              right: 16,
              ...styles,
              ...props.style,
            },
          })}
        >
          {children}
        </TooltipContentContainer>
      </FloatingPortal>
    )
  },
)
