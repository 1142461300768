import { groupByToEntries } from 'arrays'
import { formatISO, startOfDay } from 'date-fns/fp'
import { pipe } from 'fp-ts/function'
import { useMemo } from 'react'
import { type UserTherapist } from '~/domains/therapist/types'
import { filterTimeSlotsByIntervals } from '../utils/filterTimeSlotsByInterval'
import { mapAvailabilitiesToSlotsByDayAndTherapist } from '../utils/mapAvailabilitiesToSlotsByDayAndTherapist'
import { padSlotsByDay } from '../utils/padSlotsByDay'
import { useAvailableTimeSlotsFirstBooking } from './useAvailableTimeSlotsFirstBooking'
import { useSelectedTimeInterval } from './useSelectedTimeInterval'
import { useSuspenseTherapyPathByIdQuery } from './useTherapyPathByIdQuery'

type UseTimeSlotsParams = {
  suggestedTherapistsProfiles: UserTherapist[]
  therapyPathId?: string
}

const useTimeSlots = ({ suggestedTherapistsProfiles, therapyPathId }: UseTimeSlotsParams) => {
  const { therapyPath } = useSuspenseTherapyPathByIdQuery(therapyPathId)

  const therapistIds = useMemo(
    () => suggestedTherapistsProfiles?.map(({ id }) => id) ?? [],
    [suggestedTherapistsProfiles],
  )

  const { items, loading } = useAvailableTimeSlotsFirstBooking({
    userIds: therapistIds,
  })

  const selectedTimeInterval = useSelectedTimeInterval()

  const slotsByDayAndTherapist = useMemo(() => {
    const availabilitiesByDay: [string, typeof items][] = groupByToEntries(items, ({ startAt }) =>
      pipe(startAt, startOfDay, formatISO),
    )

    return mapAvailabilitiesToSlotsByDayAndTherapist(availabilitiesByDay, therapyPath.type)
  }, [items, therapyPath.type])

  const filtered = useMemo(
    () =>
      !selectedTimeInterval?.length
        ? slotsByDayAndTherapist
        : filterTimeSlotsByIntervals(slotsByDayAndTherapist, selectedTimeInterval),
    [selectedTimeInterval, slotsByDayAndTherapist],
  )

  return {
    loading,
    slotsByDayAndTherapist,
    slotsByDayAndTherapistFilteredByTimeInterval: filtered,
  }
}

type UseAvailableTimeSlotsByDayParams = {
  suggestedTherapistsProfiles: UserTherapist[]
  therapyPathId?: string
}

export const useAvailableTimeSlotsByDay = ({
  suggestedTherapistsProfiles,
  therapyPathId,
}: UseAvailableTimeSlotsByDayParams) => {
  const timeSlots = useTimeSlots({ suggestedTherapistsProfiles, therapyPathId })

  const { loading, slotsByDayAndTherapist, slotsByDayAndTherapistFilteredByTimeInterval } = timeSlots

  const slotsByDay = useMemo(() => padSlotsByDay(slotsByDayAndTherapist), [slotsByDayAndTherapist])

  const slotsByDayFilteredByTimeInterval = useMemo(
    () => padSlotsByDay(slotsByDayAndTherapistFilteredByTimeInterval),
    [slotsByDayAndTherapistFilteredByTimeInterval],
  )

  return {
    loading,
    slotsByDay,
    slotsByDayFilteredByTimeInterval,
  }
}
