import { Flex, OverflowAuto } from 'cdk'
import { Icon } from 'icons'
import { memo } from 'react'
import { Chip } from 'ui'
import { Price } from '~/components/Price'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { StartTherapyPathIcon } from '~/domains/agenda/components/StartTherapyPath/components/StartTherapyPathIcon'
import {
  colorByTherapyPathName,
  StartTherapyPathName,
} from '~/domains/agenda/components/StartTherapyPath/components/StartTherapyPathName'
import { isFirstFreeSessionTherapyPathType } from '~/domains/therapies/utils'
import { useTranslation } from '~/i18n/hooks/useTranslation'
import { type TranslationId } from '~/i18n/types'
import { type TherapyTherapyPathType } from '~/types/graphql'
import { getTherapySessionCostsByTherapyPath } from '~/utils/getTherapySessionCostsByTherapyPath'
import { ClinicalDirectorQuoteCard } from '../ClinicalDirectorQuote/ClinicalDirectorQuoteCard'

type TabItemContentProps = {
  therapyPathType: TherapyTherapyPathType
}

const translationQuoteForTherapyPathType: Record<
  TherapyTherapyPathType,
  Extract<TranslationId, `formFlow.forWhom.pathChoice.steps.options.${string}.quote`>
> = {
  COUPLES_PSYCHOTHERAPY: 'formFlow.forWhom.pathChoice.steps.options.couples.quote',
  MYSELF_PSYCHIATRY: 'formFlow.forWhom.pathChoice.steps.options.psychiatry.quote',
  MYSELF_PSYCHOTHERAPY: 'formFlow.forWhom.pathChoice.steps.options.myself.quote',
  PATH_ASSERTIVE_COMMUNICATION: 'formFlow.forWhom.pathChoice.steps.options.myself.quote',
  PATH_COACHING: 'formFlow.forWhom.pathChoice.steps.options.coaching.quote',
  PATH_NUTRITION_DCA: 'formFlow.forWhom.pathChoice.steps.options.dca.quote',
  PATH_NUTRITION_WEIGHT_LOSS: 'formFlow.forWhom.pathChoice.steps.options.nutritionist.quote',
  PATH_SEXOLOGY: 'formFlow.forWhom.pathChoice.steps.options.sexology.quote',
  PATH_SLEEP: 'formFlow.forWhom.pathChoice.steps.options.myself.quote',
  UNDERAGE_PSYCHOTHERAPY: 'formFlow.forWhom.pathChoice.steps.options.myself.quote',
}

const translationSessionDurationForTherapyPathType: Record<
  TherapyTherapyPathType,
  Extract<TranslationId, `startTherapyModal.${string}.sessionDuration`>
> = {
  COUPLES_PSYCHOTHERAPY: 'startTherapyModal.therapyCouple.sessionDuration',
  MYSELF_PSYCHIATRY: 'startTherapyModal.psychiatry.sessionDuration',
  MYSELF_PSYCHOTHERAPY: 'startTherapyModal.therapyMyself.sessionDuration',
  PATH_ASSERTIVE_COMMUNICATION: 'startTherapyModal.therapyMyself.sessionDuration',
  PATH_COACHING: 'startTherapyModal.coaching.sessionDuration',
  PATH_NUTRITION_DCA: 'startTherapyModal.dca.sessionDuration',
  PATH_NUTRITION_WEIGHT_LOSS: 'startTherapyModal.nutritionist.sessionDuration',
  PATH_SEXOLOGY: 'startTherapyModal.sexology.sessionDuration',
  PATH_SLEEP: 'startTherapyModal.therapyMyself.sessionDuration',
  UNDERAGE_PSYCHOTHERAPY: 'startTherapyModal.therapyMyself.sessionDuration',
}

const translationTalkWithForTherapyPathType: Record<
  TherapyTherapyPathType,
  Extract<TranslationId, `startTherapyModal.${string}.talkWith`>
> = {
  COUPLES_PSYCHOTHERAPY: 'startTherapyModal.therapyCouple.talkWith',
  MYSELF_PSYCHIATRY: 'startTherapyModal.psychiatry.talkWith',
  MYSELF_PSYCHOTHERAPY: 'startTherapyModal.therapyMyself.talkWith',
  PATH_ASSERTIVE_COMMUNICATION: 'startTherapyModal.therapyMyself.talkWith',
  PATH_COACHING: 'startTherapyModal.coaching.talkWith',
  PATH_NUTRITION_DCA: 'startTherapyModal.dca.talkWith',
  PATH_NUTRITION_WEIGHT_LOSS: 'startTherapyModal.nutritionist.talkWith',
  PATH_SEXOLOGY: 'startTherapyModal.sexology.talkWith',
  PATH_SLEEP: 'startTherapyModal.therapyMyself.talkWith',
  UNDERAGE_PSYCHOTHERAPY: 'startTherapyModal.therapyMyself.talkWith',
}

const translationDetailsForTherapyPathType: Record<
  TherapyTherapyPathType,
  {
    left: Extract<TranslationId, `paymentPlan.${string}.left.detail`>
    right: Extract<TranslationId, `paymentPlan.${string}.right.detail`>
  }
> = {
  COUPLES_PSYCHOTHERAPY: {
    left: 'paymentPlan.therapyCouple.left.detail',
    right: 'paymentPlan.therapyMyself.right.detail',
  },
  MYSELF_PSYCHIATRY: { left: 'paymentPlan.psychiatry.left.detail', right: 'paymentPlan.therapyMyself.right.detail' },
  MYSELF_PSYCHOTHERAPY: {
    left: 'paymentPlan.therapyMyself.left.detail',
    right: 'paymentPlan.therapyMyself.right.detail',
  },
  PATH_ASSERTIVE_COMMUNICATION: {
    left: 'paymentPlan.therapyMyself.left.detail',
    right: 'paymentPlan.therapyMyself.right.detail',
  },
  PATH_COACHING: { left: 'paymentPlan.coaching.left.detail', right: 'paymentPlan.therapyMyself.right.detail' },
  PATH_NUTRITION_DCA: { left: 'paymentPlan.dca.left.detail', right: 'paymentPlan.therapyMyself.right.detail' },
  PATH_NUTRITION_WEIGHT_LOSS: {
    left: 'paymentPlan.nutritionist.left.detail',
    right: 'paymentPlan.therapyMyself.right.detail',
  },
  PATH_SEXOLOGY: { left: 'paymentPlan.sexology.left.detail', right: 'paymentPlan.therapyMyself.right.detail' },
  PATH_SLEEP: { left: 'paymentPlan.therapyMyself.left.detail', right: 'paymentPlan.therapyMyself.right.detail' },
  UNDERAGE_PSYCHOTHERAPY: { left: 'paymentPlan.therapyMyself.left.detail', right: 'paymentPlan.call.right.detail' },
}

const translationPlanNamesForTherapyPathType: Record<
  TherapyTherapyPathType,
  {
    left: Extract<TranslationId, `paymentPlan.${string}.left.planName`>
    right: Extract<TranslationId, `paymentPlan.${string}.right.planName`>
  }
> = {
  COUPLES_PSYCHOTHERAPY: {
    left: 'paymentPlan.therapyCouple.left.planName',
    right: 'paymentPlan.therapyMyself.right.planName',
  },
  MYSELF_PSYCHIATRY: {
    left: 'paymentPlan.psychiatry.left.planName',
    right: 'paymentPlan.therapyMyself.right.planName',
  },
  MYSELF_PSYCHOTHERAPY: {
    left: 'paymentPlan.therapyMyself.left.planName',
    right: 'paymentPlan.therapyMyself.right.planName',
  },
  PATH_ASSERTIVE_COMMUNICATION: {
    left: 'paymentPlan.therapyMyself.left.planName',
    right: 'paymentPlan.therapyMyself.right.planName',
  },
  PATH_COACHING: { left: 'paymentPlan.coaching.left.planName', right: 'paymentPlan.therapyMyself.right.planName' },
  PATH_NUTRITION_DCA: { left: 'paymentPlan.dca.left.planName', right: 'paymentPlan.therapyMyself.right.planName' },
  PATH_NUTRITION_WEIGHT_LOSS: {
    left: 'paymentPlan.nutritionist.left.planName',
    right: 'paymentPlan.therapyMyself.right.planName',
  },
  PATH_SEXOLOGY: { left: 'paymentPlan.sexology.left.planName', right: 'paymentPlan.therapyMyself.right.planName' },
  PATH_SLEEP: { left: 'paymentPlan.therapyMyself.left.planName', right: 'paymentPlan.therapyMyself.right.planName' },
  UNDERAGE_PSYCHOTHERAPY: {
    left: 'paymentPlan.therapyMyself.left.planName',
    right: 'paymentPlan.therapyMyself.right.planName',
  },
}

const FirstSessionFree = () => (
  <Flex $align="center" $direction="row" $gap={8}>
    <Icon colorName="darker" name="gift" size={24} />
    <Chip kind="darker">
      <Translation id="formFlow.firstTherapySessionFree.label" />
    </Chip>
  </Flex>
)

const TherapySessionDuration = ({ therapyPathType }: TabItemContentProps) => (
  <Flex $align="center" $direction="row" $gap={8}>
    <Flex $shrink={0}>
      <Icon colorName="darker" name="timer" size={24} />
    </Flex>
    <Flex $shrink={1}>
      <TranslationMarkdown
        colorName="darker"
        id={translationSessionDurationForTherapyPathType[therapyPathType]}
        kind="paragraph"
      />
    </Flex>
  </Flex>
)

const TherapySessionTalkWith = ({ therapyPathType }: TabItemContentProps) => (
  <Flex $align="center" $direction="row" $gap={8}>
    <Icon colorName="darker" name="user-badge-check" size={24} />
    <TranslationMarkdown
      colorName="darker"
      id={translationTalkWithForTherapyPathType[therapyPathType]}
      kind="paragraph"
    />
  </Flex>
)

export const TabItemContent = memo(({ therapyPathType }: TabItemContentProps) => {
  const costs = getTherapySessionCostsByTherapyPath(therapyPathType)
  const leftDetailTranslationId = useTranslation(translationDetailsForTherapyPathType[therapyPathType].left)
  const rightDetailTranslationId = useTranslation(translationDetailsForTherapyPathType[therapyPathType].right)

  const isFirstFreeSession = isFirstFreeSessionTherapyPathType(therapyPathType)

  return (
    <OverflowAuto $gap={16} $pb={12}>
      <Flex $align="center" $direction="row" $gap={12}>
        <StartTherapyPathIcon size={48} therapyPathType={therapyPathType} />
        <Flex $shrink={1}>
          <StartTherapyPathName therapyPathType={therapyPathType} />
        </Flex>
      </Flex>
      <ClinicalDirectorQuoteCard
        backgroundColorName="neutral-20"
        quote={translationQuoteForTherapyPathType[therapyPathType]}
      />
      <Flex $gap={16}>
        {isFirstFreeSession && <FirstSessionFree />}
        <TherapySessionDuration therapyPathType={therapyPathType} />
        <TherapySessionTalkWith therapyPathType={therapyPathType} />
        {costs.firstTherapySessionCost !== 0 ? (
          <Flex $direction="row">
            <Flex $borderColorName="neutral-50" $borderSizeRight={1} $grow={1}>
              <Price
                additionalInfo={<Translation id={translationPlanNamesForTherapyPathType[therapyPathType].left} />}
                amount={costs.firstTherapySessionCost}
                detail={leftDetailTranslationId}
              />
            </Flex>
            <Flex $grow={1} $pl={16}>
              <Price
                additionalInfo={<Translation id={translationPlanNamesForTherapyPathType[therapyPathType].right} />}
                amount={costs.therapySessionCost}
                color={colorByTherapyPathName[therapyPathType]}
                detail={rightDetailTranslationId}
              />
            </Flex>
          </Flex>
        ) : (
          <Flex $direction="row">
            <Price
              amount={costs.therapySessionCost}
              color={colorByTherapyPathName[therapyPathType]}
              detail={leftDetailTranslationId}
            />
          </Flex>
        )}
      </Flex>
    </OverflowAuto>
  )
})
