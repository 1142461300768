import { type TherapyTherapyPathType } from '~/types/graphql'

const firstFreeSessionTherapyPathTypes = [
  'COUPLES_PSYCHOTHERAPY',
  'MYSELF_PSYCHOTHERAPY',
  'PATH_COACHING',
  'PATH_SEXOLOGY',
  'PATH_NUTRITION_WEIGHT_LOSS',
  'PATH_NUTRITION_DCA',
] as const

type FirstFreeSessionTherapyPathType = Extract<
  TherapyTherapyPathType,
  (typeof firstFreeSessionTherapyPathTypes)[number]
>

export const isFirstFreeSessionTherapyPathType = (
  type: TherapyTherapyPathType,
): type is FirstFreeSessionTherapyPathType =>
  firstFreeSessionTherapyPathTypes.includes(type as FirstFreeSessionTherapyPathType)

export const therapyPathTypeToFormFlowPrefilledConceptId: Record<TherapyTherapyPathType, string> = {
  COUPLES_PSYCHOTHERAPY: 'fcp_shb8r10sn17jcg4c',
  MYSELF_PSYCHIATRY: 'fcp_d96b49iwat7eqym9',
  MYSELF_PSYCHOTHERAPY: 'fcp_01nupy985ryhjkfz',
  PATH_ASSERTIVE_COMMUNICATION: 'fcp_pcte7g1kzqtybxj2',
  PATH_COACHING: 'fcp_75n7jtbqpox2d2s9',
  PATH_SEXOLOGY: 'fcp_yi5jfjn5vwespxw3',
  PATH_SLEEP: 'fcp_sc50mrhiak6jhcvs',
  UNDERAGE_PSYCHOTHERAPY: 'fcp_1pplin5t1u43vud6',
  PATH_NUTRITION_WEIGHT_LOSS: 'fcp_x9jv6djz1mr1ddbr',
  PATH_NUTRITION_DCA: 'fcp_wxjsctsmgsudhsu7',
}
